import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import areaMeasureIcon from "../images/areaMeasure.svg";
import ruleIcon from "../images/rule.svg";
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function Measure(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <IconButton aria-label="Línea" className={classes.margin} size="large" onClick={() => {
                props.customparameters.setMeasure("lenght");
            }}>
                <img src={ruleIcon} width="48px"/>
                <br/>
                
            </IconButton>
            Per&iacute;metro
            <IconButton aria-label="Area" className={classes.margin} size="large" onClick={() => {
                props.customparameters.setMeasure("area");
            }}>
                <img src={areaMeasureIcon} width="48px"/>
            </IconButton>
            &Aacute;rea
        </React.Fragment>
    );
}