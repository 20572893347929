import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    TextField, Button, RadioGroup, FormControlLabel, Radio, Typography, Paper, Grid, Divider, Card, CardContent, Box
} from '@material-ui/core';
import axios from 'axios';
import PatenteService from '../apis/PatenteService';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(4),
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
    },
    inputField: {
        marginBottom: theme.spacing(2),
    },
    button: {
        marginTop: theme.spacing(2),
    },
    result: {
        marginTop: theme.spacing(4),
    },
    resultCard: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[2],
    },
    resultContent: {
        padding: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
}));

const Calculator = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [priceSale, setPriceSale] = useState('');
    const [pricePreviousSale, setPricePreviousSale] = useState('');
    const [improvementsValue, setImprovementsValue] = useState('');
    const [constructionsValue, setConstructionsValue] = useState('');
    const [acquisitionDate, setAcquisitionDate] = useState('');
    const [liquidationDate, setLiquidationDate] = useState('');
    const [propertyType, setPropertyType] = useState('1');
    const [results, setResults] = useState(null);
    const [error, setError] = useState('');

    const handleCalculate = async () => {
        if (!priceSale || !pricePreviousSale || !improvementsValue || !constructionsValue || !acquisitionDate || !liquidationDate) {
            setError('Todos los campos son obligatorios.');
            return;
        }

        setError('');
        const patentService = await PatenteService;
        try {
            const response = await patentService.calculateCapitalGain(liquidationDate, acquisitionDate, priceSale, pricePreviousSale, improvementsValue, constructionsValue, propertyType);
            setResults(response.data[0]);
        } catch (error) {
            console.error('Error al enviar datos:', error);
        }
    };

    const handleReset = () => {
        setPriceSale('');
        setPricePreviousSale('');
        setImprovementsValue('');
        setConstructionsValue('');
        setAcquisitionDate('');
        setLiquidationDate('');
        setPropertyType('1');
        setResults(null);
        setError('');
    };

    return (
        <Paper className={classes.container}>
            <Typography variant="h6" className={classes.title}>Calculadora de Plusval&iacute;a</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Precio de Venta"
                        value={priceSale}
                        onChange={(e) => setPriceSale(e.target.value)}
                        fullWidth
                        className={classes.inputField}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Precio de Venta Anterior"
                        value={pricePreviousSale}
                        onChange={(e) => setPricePreviousSale(e.target.value)}
                        fullWidth
                        className={classes.inputField}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Valor en Mejoras"
                        value={improvementsValue}
                        onChange={(e) => setImprovementsValue(e.target.value)}
                        fullWidth
                        className={classes.inputField}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Valor Construcciones"
                        value={constructionsValue}
                        onChange={(e) => setConstructionsValue(e.target.value)}
                        fullWidth
                        className={classes.inputField}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Fecha Adquisición"
                        type="date"
                        value={acquisitionDate}
                        onChange={(e) => setAcquisitionDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={classes.inputField}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Fecha Liquidación"
                        type="date"
                        value={liquidationDate}
                        onChange={(e) => setLiquidationDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        className={classes.inputField}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1">Tipo Predio</Typography>
                        <RadioGroup
                            row
                            value={propertyType}
                            onChange={(e) => setPropertyType(e.target.value)}
                            style={{ marginLeft: theme.spacing(2) }}
                        >
                            <FormControlLabel value="1" control={<Radio color="primary" />} label="Urbano" />
                            <FormControlLabel value="0" control={<Radio color="primary" />} label="Rural" />
                        </RadioGroup>
                    </Box>
                </Grid>
                {error && (
                    <Grid item xs={12}>
                        <Typography color="error">{error}</Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleReset}
                        style={{ marginRight: theme.spacing(2) }}
                    >
                        Reestablecer
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleCalculate}
                    >
                        Calcular
                    </Button>
                </Grid>
                {results && (
                    <Grid item xs={12} className={classes.result}>
                        <Typography variant="h6">Resultados</Typography>
                        <Divider />
                        <Card className={classes.resultCard}>
                            <CardContent className={classes.resultContent}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle1">Utilidad Bruta:</Typography>
                                        <Typography variant="body1">${results.utilBruta}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle1">Utilidad Líquida:</Typography>
                                        <Typography variant="body1">${results.utilLiquida}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle1">5% por año:</Typography>
                                        <Typography variant="body1">${results.utildAnio}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle1">Utilidad Neta:</Typography>
                                        <Typography variant="body1">${results.utilNeta}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle1">Impuesto Alcabala:</Typography>
                                        <Typography variant="body1">${results.vAclabala}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="subtitle1">Impuesto Plusvalía:</Typography>
                                        <Typography variant="body1">${results.vPlusvalia}</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Paper>
    );
};

export default Calculator;