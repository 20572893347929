const defaultState={breadcrumb:"estado inicial"};
function reducer(state= defaultState, {type, payload})
{
    switch(type){
        case 'actualizarBreadCrumb':{
            return {
            ...state,
            breadcrumb: payload
            }
        }
        default:
            return state;
    }
}

export default reducer;