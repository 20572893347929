import {INICIAR_IMPORACION,OBTENER_IMPORTACION} from "../actions/importarExcel";

let estadoInicial = {
    imagen: "",
    documentoEjemplo: "",
    datosExcel: {},
}

export default function (state=estadoInicial, action) {
    switch (action.type) {
        case INICIAR_IMPORACION:
            return{
                ...state,
                imagen: action.payload.imagen,
                documentoEjemplo: action.payload.documentoEjemplo
            }
        case  OBTENER_IMPORTACION:
            return{
                ...state,
                datosExcel: action.payload
            }
        default:
            return state
    }
}
