import React, { useState, useEffect } from 'react';
import {
  Modal,
  Fade,
  Backdrop,
  IconButton,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import PatenteService from '../../apis/PatenteService';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    width: '70%',
    borderRadius: theme.shape.borderRadius,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const NuevoEstablecimiento = ({
  open,
  handleClose,
  handleAddEstablishment,
  handleUpdateEstablishment,
  enableEdition,
  existingEstablishment,
  patentId,
  userName
}) => {
  const classes = useStyles();

  const [establecimientoData, setEstablecimientoData] = useState({
    callePrincipal: '',
    calleSecundaria: '',
    numeroEdificacion: '',
    idBarrio: '',
    catalogoMatriz: '',
    correoElectronico: '',
    establecimientoComercial: '',
    numeroEstablecimiento: '',
    parroquia: '',
    estado: 1,
    usuario: userName,
    referencia: '',
    telefono: '',
    celular: '',
    figuraLegal: ''
  });

  const [parroquias, setParroquias] = useState([]);
  const [barrios, setBarrios] = useState([]);
  const [figuraLegalOptions, setFiguraLegalOptions] = useState([]);
  const [catalogoMatrizOptions, setCatalogoMatrizOptions] = useState([]);
  const [idCanton, setIdCanton] = useState(null);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [cantonResponse, figuraLegalResponse, catalogoMatrizResponse] = await Promise.all([
          PatenteService.getCantonId(),
          PatenteService.getFiguraLegal(),
          PatenteService.getCodigoMatriz()
        ]);

        const canton = cantonResponse.data[0];
        setIdCanton(canton.idCanton);

        const parroquiasResponse = await PatenteService.getParroquiasList(canton.idCanton);
        setParroquias(parroquiasResponse.data);
        setFiguraLegalOptions(figuraLegalResponse.data);
        setCatalogoMatrizOptions(catalogoMatrizResponse.data);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (existingEstablishment) {
      setEstablecimientoData(existingEstablishment);
    }
  }, [existingEstablishment]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEstablecimientoData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleParroquiaChange = async (e) => {
    const parroquiaId = e.target.value;
    handleChange(e);

    try {
      const barriosResponse = await PatenteService.getBarrioList(parroquiaId);
      setBarrios(barriosResponse.data);
    } catch (error) {
      console.error('Error fetching barrios:', error);
    }
  };

  const handleSubmit = () => {
    const toSend = { ...establecimientoData, idPatente: patentId };
    toSend["usuario"]=userName;
    if (existingEstablishment) {
      handleUpdateEstablishment(toSend);
    } else {
      handleAddEstablishment(toSend);
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {existingEstablishment ? 'Modificar Establecimiento' : 'Nuevo Establecimiento'}
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="callePrincipal" label="Calle Principal" name="callePrincipal" value={establecimientoData.callePrincipal} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="calleSecundaria" label="Calle Secundaria" name="calleSecundaria" value={establecimientoData.calleSecundaria} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="numeroEdificacion" label="Número Edificación" name="numeroEdificacion" value={establecimientoData.numeroEdificacion} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="catalogoMatriz-label">Catálogo Matriz</InputLabel>
                  <Select labelId="catalogoMatriz-label" id="catalogoMatriz" name="catalogoMatriz" value={establecimientoData.catalogoMatriz} onChange={handleChange} label="Catálogo Matriz">
                    {catalogoMatrizOptions.map((option) => (
                      <MenuItem key={option.catId} value={option.catId}>
                        {option.catNombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="telefono" label="Teléfono" name="telefono" value={establecimientoData.telefono} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="celular" label="Celular" name="celular" value={establecimientoData.celular} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="correoElectronico" label="Correo Electrónico" name="correoElectronico" value={establecimientoData.correoElectronico} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="establecimientoComercial" label="Nombre Comercial" name="establecimientoComercial" value={establecimientoData.establecimientoComercial} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="numeroEstablecimiento" label="Número Establecimiento" name="numeroEstablecimiento" value={establecimientoData.numeroEstablecimiento} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required id="referencia" label="Referencia" name="referencia" value={establecimientoData.referencia} onChange={handleChange} className={classes.textField} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="parroquia-label">Parroquia</InputLabel>
                  <Select labelId="parroquia-label" id="parroquia" name="parroquia" value={establecimientoData.parroquia} onChange={handleParroquiaChange} label="Parroquia">
                    {parroquias.map((parroquia) => (
                      <MenuItem key={parroquia.idParroquia} value={parroquia.idParroquia}>
                        {parroquia.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="barrio-label">Barrio</InputLabel>
                  <Select labelId="barrio-label" id="idBarrio" name="idBarrio" value={establecimientoData.idBarrio} onChange={handleChange} label="Barrio">
                    {barrios.map((barrio) => (
                      <MenuItem key={barrio.idBarrio} value={barrio.idBarrio}>
                        {barrio.nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="figuraLegal-label">Figura Legal</InputLabel>
                  <Select labelId="figuraLegal-label" id="figuraLegal" name="figuraLegal" value={establecimientoData.figuraLegal} onChange={handleChange} label="Figura Legal">
                    {figuraLegalOptions.map((option) => (
                      <MenuItem key={option.catId} value={option.catId}>
                        {option.catNombre}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" required className={classes.formControl}>
                  <InputLabel id="estado-label">Estado</InputLabel>
                  <Select labelId="estado-label" id="estado" name="estado" value={establecimientoData.estado} onChange={handleChange} label="Estado">
                    <MenuItem value={1}>Activo</MenuItem>
                    <MenuItem value={0}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {enableEdition && (
              <Button type="button" variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
                {existingEstablishment ? 'Guardar Cambios' : 'Registrar Establecimiento'}
              </Button>
            )}
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default NuevoEstablecimiento;
