import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, Paper, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import PatenteService from '../apis/PatenteService';
import { NotificationManager } from 'react-notifications';
import { connect } from 'react-redux';
import actualizarJson from '../../redux/actions/actualizarJson';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(10, 3, 3),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    marginTop: theme.spacing(-8),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginComponent = ({ actualizarJson, json }) => {

  let history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const patentService = await PatenteService;
    patentService.login(email, password).then(result => {
      const isValid = result.data;
      if (isValid) {
        actualizarJson({ generalJson: { loginInfo: isValid , cedula:email, edit:0} });
        history.push("/check_user_state");
      } else {
        NotificationManager.error("Usuario Inválido.", "", 5000, () => { });
      }
    }, error => { });
  };

  const handleRegister = () => {
    history.push("/register");
  };
  const handleForgot = () => {
    history.push("/forgot_password");
  };

  return (
    <Grid container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Iniciar sesión
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Iniciar sesión
          </Button>
          <Grid container>
            <Grid item xs>
              <Button variant="body2" onClick={handleForgot}>
                ¿Olvidaste la contraseña?
              </Button>
            </Grid>
            <Grid item>
              <Button variant="body2" onClick={handleRegister}>
                Crear cuenta
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    json: state.generalJson.json
  };
}
const mapDispatchToProps = {
  actualizarJson
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);