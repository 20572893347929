import React from 'react';
import { Button, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { NotificationManager } from 'react-notifications';
import PatenteService from '../apis/PatenteService';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start', // Justificar al inicio
    alignItems: 'center',
    padding: theme.spacing(10, 3, 3),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    marginTop: theme.spacing(3), // Ajustar margen superior si es necesario
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ClosePatentComponent = ({ json }) => {
  let history = useHistory();
  const classes = useStyles();

  const handleYes = async () => {
    const patentService = await PatenteService;
    try {
      const currentDate = new Date().toISOString().split('T')[0];
      const data = {
        idPatente: json["patenteId"],
        estado: 0,
        fechaCierrePatente: currentDate
      };
      const response = await patentService.cancelPatent(data);
      if (response.status === 200) {
        const objRegister={
          idPatente:json["patenteId"],
          usuario:'echiza',
          observacion:'SE CANCELA PATENTE'
        };
        patentService.eventRegister(objRegister);
        NotificationManager.success("Patente Eliminada", "", 5000, () => {});
      } else {
        NotificationManager.error("Ha ocurrido un Error, intente nuevamente", "", 5000, () => {});
      }
    } catch (error) {
      NotificationManager.error("Ha ocurrido un Error, intente nuevamente", "", 5000, () => {});
    }
    history.push("/company_profile");
  };

  const handleNo = () => {
    NotificationManager.error("Acción Cancelada", "", 5000, () => {});
    history.push("/company_profile");
  };

  return (
    <Grid container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Está seguro que desea cerrar la Patente?
        </Typography>
        <Typography component="p">
          (Recuerde que esta acción no es reversible)
        </Typography>
        <Grid container spacing={2} justify="center" className={classes.form}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleYes}
              className={classes.submit}
            >
              Sí
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNo}
              className={classes.submit}
            >
              No
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    json: state.generalJson.json
  };
}

export default connect(mapStateToProps, null)(ClosePatentComponent);
