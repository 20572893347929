import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import '../../assets/css/overlay-pannel.css';
import { OverlayPanel } from 'primereact/overlaypanel';
import Tooltip from '@material-ui/core/Tooltip';
import PublicIcon from '@material-ui/icons/Public';
import StreetviewIcon from '@material-ui/icons/Streetview';
import "./css/filters.css";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function IndividualButton(props) {
  const classes = useStyles();
  const op = useRef(null);
  const[iconState,setIconState]=useState(1);
  const onDivClick = (e) => {
    if (props.type==="customMethod")props.customparameters();
    else op.current.toggle(e);
  }
  useEffect(()=>{
    console.log(props);
  },[]);
  const changeIconState=(icon)=>{
    setIconState(icon);
  }
  return (
    <div>
      <div className="card">
        {/*<Tooltip title={props.toolTipTitle} placement={props.toolTipPosition}>*/}
        <div className="individualButton">

          <IconButton aria-label="delete" className={classes.margin} size="large" onClick={onDivClick}>
            {props.svg!==null&&
              <img src={props.svg} width="48px" className="filter-white"/>
            }
            {(typeof props.icon!=="string"&&props.svg===null)&&
            <props.icon fontSize="large" style={{ color: "white" }}/>
            }
            {(typeof props.icon==="string"&&props.svg===null)&&
            <React.Fragment>
              {iconState===1&&
              <StreetviewIcon fontSize="large" style={{ color: "white" }} />
              }
              {iconState===2&&
              <PublicIcon fontSize="large" style={{ color: "white" }} />
              }
            </React.Fragment>
            }
          </IconButton>
          <br/>
          <span style={{color:"#ffffff"}}>{props.toolTipTitle}</span>
          <OverlayPanel ref={op} id="overlay_panel" style={{ width: props.width, marginLeft: "auto" }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            className="overlaypanel-demo" dismissable={true}
            showCloseIcon dismissable={true}
            id="test"
          >
            {props.component !== null &&
              <props.component customparameters={props.customparameters} changeIconState={changeIconState}/>
            }
          </OverlayPanel>

        </div>
        {/*</Tooltip>*/}
      </div>
    </div>
  );
}