import axios from 'axios';

class PatenteService {
  constructor() {
    this.api_url = "";
    this.geoserver_url = "";
    this.filesAddress = "";
    
    fetch(`${process.env.PUBLIC_URL}/config.json`)
      .then((response) => response.json())
      .then((initOptions) => {
        this.api_url = initOptions.api_url;
        this.geoserver_url = initOptions.geoserver_url;
        this.filesAddress = initOptions.api_images_server;
      })
      .catch((error) => {
        console.error('Error loading config.json:', error);
      });
  }

  listPatent = () => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaPatentes`);
  }

  listActivities = (establishmentId) => {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaEstaActivi/${establishmentId}`);
  }

  patentList = (personId) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaPatentes/${personId}`);
  }

  getUserProfile = (personId) => {
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/propietario/${personId}`);
  }
  updateUserInfo= (data)=>{
    return axios.post(`${this.api_url}/catastro/rest/v1.0/consultas/modificarPropietario`,data);
  }

  createPatent = (data) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/ingresoPatente`, data);
  }

  listEstablishment = (patentId) => {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaEstableimiento/${patentId}`);
  }
  uploadDocument = (data) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/documentos`, data);
  }
  createEstablishment = (establishmentData) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/ingresoEstablecimiento`, establishmentData);
  }

  createEstablishmentActivity = (activity) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/ingresoEstablecimientoActividad`, activity);
  }

  login = (user, pass) => {
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/login/${user}/${pass}`);
  }

  register = (obj) => {
    return axios.post(`${this.api_url}/catastro/rest/v1.0/consultas/contribuyente`, obj);
  }

  listCatalogs = () => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaCuentasActivosPasivos`);
  }

  listCatalogDescription = (catNombre) => {
    if (catNombre === "ACTIVO") {
      return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaDescripcionActivos`);
    }
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaDescripcionPasivos`);
  }

  listPeriods = () => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listarPeriodos`);
  }

  listAccounts = (patentId) => {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaCuentas/${patentId}`);
  }
  newAccount = (obj) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/ingresoCuentas`,obj);
  }
  listActivitiesSearch = (establishmentId) => {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listarActividade/${establishmentId}`);
  }
  checkUserState = (cedula) => {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/consultaEstado/${cedula}`);
  }
  calculateCapitalGain= (liquidationDate,acquisitionDate,priceSale,pricePreviousSale,improvementsValue,constructionsValue,propertyType) => {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/calcularP/${liquidationDate}/${acquisitionDate}/${priceSale}/${pricePreviousSale}/${improvementsValue}/${constructionsValue}/${propertyType}`);
  }
  listDocumentsByPatentId=(patentId)=>{
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaDocumentos/${patentId}`);
  }
  downloadDocumentById=(documentId)=>{
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listarDocumentosDescarga/${documentId}`);
  }
  
  forgotPassword = (obj) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/modificarUsuarioPropietario`,obj);
  }

  cancelPatent= (obj) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/modificarEstadoPatente`,obj);
  }
  eventRegister= (obj) => {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/ingresoTramite`,obj);
  }
  async updateEstablishment(establishment) {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/modificarEstablecimiento`,establishment);
  }

  async updatePatent(patent) {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/modificarPatente`,patent);
  }

  async updateActivity(activity) {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/modificarEstablecimientoActividad`,activity);
  }

  async updateAccount(account) {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/modificarCuentas`,account);
  }
  async getCantonId() {
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaCantonParroquia`);
  }
  async getParroquiasList(cantonId) {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaParroquiasCanton/${cantonId}`);
  }
  async getBarrioList(parroquiaId) {
    return axios.get(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaBarrioParroquia/${parroquiaId}`);
  }
  async getFiguraLegal(){
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaLocal`);
  }

  async getCodigoMatriz(){
    return axios.post(`${this.api_url}/patentes/rest/v1.0/patentesTrimites/listaTipoEstablecimiento`);
  }
}

export default new PatenteService();
