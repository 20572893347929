import axios from 'axios';

class GeneralService {
  constructor() {
    this.api_url = "";
    this.geoserver_url = "";
    this.filesAddress="";
    fetch(`${process.env.PUBLIC_URL}/config.json`).then((r) => r.json())
      .then((initOptions) => {
        this.api_url = initOptions.api_url;
        this.geoserver_url = initOptions.geoserver_url;
        this.filesAddress=initOptions.api_images_server;
      });
  }
  getDebts = (id) => {
    return axios.get(`${this.api_url}/cajaunica/rest/caja/recaudacion/${id}`);
  }
  getProcedure = (id) => {
    return axios.get(`${this.api_url}/catastro/rest/v1.0/despachoTramite/tramiteguia/${id}`);
  }
  getDenunciaTypes=() =>{
    return axios.post(`https://servicios.municipiodemejia.gob.ec/catastro/rest/v1.0/consultas/listaCatalogo`)
  }
  sendDenuncia=(denunciaObj) =>{
    return axios.post(`https://servicios.municipiodemejia.gob.ec/catastro/rest/v1.0/consultas/denuncia`,denunciaObj)
  }
  getProcedureByDni = (id) => {
    return axios.get(`${this.api_url}/catastro/rest/v1.0/despachoTramite/tramiteiden/${id}`);
  }
  obtenerPredio = (id,sinat=0) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/consultarInformePreliminar/${id}`);
  }
  busquedaCedula = (cedula) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/buscarPredioPorCedula/${cedula}`);

  }
  busquedaCatastral = (catastro) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/buscarPredioPorClaveCatastral/${catastro}`);
  }

  busquedaIcusCedula = (cedula) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/buscarPredioPorCedula/${cedula}`);
  }
  busquedaIcusClaveCatastral = (catastro) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/buscarPredioPorClaveCatastral/${catastro}`);
  }
  obtenerInformePreliminarIrm = (a, b) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/consultarInformePreliminarPdf/${a}`);
  }
  crearInformeIrm = (a, b) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/crearInforme/${a}`);
  }
  descargarInformeIrm = (codigo) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/irm/consultarInformePdf/${codigo}`);
  }
  obtenerInformePreliminarIcus = (predio, sinad, x, y) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformeGeneralPreliminarPdf/${predio}/${x}/${y}`);
  }
  crearInformeIcus = (predio, sinad, x, y) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/crearInformeGeneral/${predio}/${x}/${y}`);

  }
  descargarInformeIcus = (codigo) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformeGeneralPdf/${codigo}`);
  }
  generarInformeVistaPreviaIcus = (predio, sinad, x, y, icus) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePreliminarPdf/${predio}/${x}/${y}/${icus}`);
  }
  generarInformeIcusProd = (predio, sinad, x, y, icus) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/crearInforme/${predio}/${x}/${y}/${icus}`);
  }
  verInformeIcusProd = (icusId) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePdf/${icusId}`);
  }

  ObtenerPredioIcus = (predio, sinat) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformeInicial/${predio}`);
  }
  ObtenerUbicacionIcus = (predio, sinad, x, y) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/registrarUbicacion/${predio}/${x}/${y}`);
  }
  ObtenerTipologia = () => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/listarTipologia`);
  }
  ObtenerActividad = (actividad, tipologia) => {
    console.log("tipo", tipologia);
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/buscarActividad/${actividad}/${tipologia}`);
  }
  getActivityReport = (preId, sinat, x, y, activityCode) => {
    return axios.post(`${this.api_url}/planificacionTerritorial/rest/icus/consultarInformePreliminar/${preId}/${x}/${y}/${activityCode}`);
  }
  getGeoServerUrl = () => {
    return this.geoserver_url;
  }


  getGeneralCategory=()=>{
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listarCoberturaCategoria`);
  }
  getGeneralSubCategory=(categoryId)=>{
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listarCoberturaSubCategoria/${categoryId}`);
  }
  getGeneralSubCategoryLayer=(subCategoryId)=>{
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listaFuenteSubcategoria/${subCategoryId}`);
  }
  getLayerImages=(layerName)=>{
    return axios.post(`${this.api_url}/catastro/rest/v1.0/consultas/listarImagenCobertura/${layerName}`);
  }

  getDynamicalData=(url)=>{
    return axios.get(url);
  }
  getPredialInfo=(catastro)=>{
    return axios.post(`${this.api_url}/catastro/rest/v1.0/consultas/buscarPredio/${catastro}`);
  }
  getPdfGeneralInfo=()=>{
    return axios.post(`${this.api_url}/catastro/rest/v1.0/consultas/listarDocumento`);
  }
  getFeaturesEx=()=>{
    return axios.get(`${this.api_url}/geoserver/globalGad/wfs?service=wfs&version=2.0.0&request=GetFeature&typeNames=globalGad:cat_predio`);
  }
  getDocumentName=(tramiteId)=>{
    return axios.get(`${this.api_url}/catastro/rest/v1.0/despachoTramite/listarDocumentosTramite/${tramiteId}`);
  }
  getFileAddress=(fileName)=>{
    return `${this.filesAddress}/TRAMITE/${fileName}`;
  }

  getPredioInfo=(catastroKey)=>{
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listaDatosPredio/${catastroKey}`);
  }

  getPropietaryInfo=(catastroKey)=>{
    return axios.get(`${this.api_url}/catastro/rest/v1.0/consultas/listaDatosPropietarios/${catastroKey}`);
  }

  getSubCategory=(id)=>{
    return axios.get(`https://globalgad.intelectus-system.com/catastro/rest/v1.0/consultas/listarCoberturaSubCategoria/${id}`);
  }
  getSubCategoryLayers=(id)=>{
    return axios.get(`https://globalgad.intelectus-system.com/catastro/rest/v1.0/consultas/listaFuenteSubcategoria/${id}`);
  }


}
export default new GeneralService();

