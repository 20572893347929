const defaultState={tituloModal:""};
function reducer(state= defaultState, {type, payload})
{
    switch(type){
        case 'actualizarTituloModal':{
            return {
            ...state,
            tituloModal: payload
            }
        }
        default:
            return state;
    }
}

export default reducer;