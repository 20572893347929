import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GeneralService from '../apis/GeneralService';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '100%',
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '600px',
        margin: 'auto',
        padding: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    textField: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
}));

export default function FormularioDenuncia() {
    const classes = useStyles();

    const [denunciaTypes, setDenunciaTypes] = useState([]);
    const [cedula, setCedula] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [catId, setCatId] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [showCaptcha, setShowCaptcha] = useState(true);

    const loadDenunciaTypes = async () => {
        const response = await GeneralService.getDenunciaTypes();
        console.log(response.data);
        setDenunciaTypes(response.data);
    };

    useEffect(() => {
        loadCaptchaEnginge(6);
        loadDenunciaTypes();
    }, []);

    const checkCaptcha = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha) == true) {
            setShowCaptcha(false);
            document.getElementById('user_captcha_input').value = "";
        }
        else {
            alert('El código no es correcto. Intente nuevamente');
            document.getElementById('user_captcha_input').value = "";
        }
    }

    const sendDenuncia = async (event) => {
        event.preventDefault();

        const objToSend = {
            "descripcion": descripcion,
            "usuario": cedula,
            "catalogo": catId,
            "cordenadaX": "0",
            "cordenadaY": "0",
            "nombreImagen": "",
            "imagen": ""
        };

        const response = await GeneralService.sendDenuncia(objToSend);

        setOpenDialog(true);
        setCedula('');
        setDescripcion('');
        setCatId('');
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <div className={classes.formWrapper}>
            {showCaptcha ?
                <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <div className="container" style={{ textAlign: "center" }}>
                        <div className="form-group">

                            <div className="col mt-3">
                                <LoadCanvasTemplate />
                            </div>

                            <div className="col mt-3">
                                <div><input placeholder="Ingrese el valor del captcha" id="user_captcha_input" name="user_captcha_input" type="text"></input></div>
                            </div>

                            <div className="col mt-3">
                                <div><button class="btn btn-primary" onClick={checkCaptcha}>Validar Captcha</button></div>
                            </div>

                        </div>
                    </div>
                    </div>
                </React.Fragment>
                :
                <>
                    <Typography variant="h4" align="center">Nueva Denuncia</Typography>
                    <form onSubmit={sendDenuncia}>
                        <FormControl className={classes.formControl} required>
                            <InputLabel id="denuncia-type-label">Tipo de Denuncia</InputLabel>
                            <Select
                                labelId="denuncia-type-label"
                                id="denuncia-type"
                                value={catId}
                                onChange={event => setCatId(event.target.value)}
                            >
                                {denunciaTypes.map(denunciaType => (
                                    <MenuItem key={denunciaType.catId} value={denunciaType.catId}>{denunciaType.catNombre}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Seleccione el tipo de denuncia</FormHelperText>
                        </FormControl>
                        <TextField required label="Cédula" value={cedula} onChange={event => setCedula(event.target.value)} className={classes.textField} />
                        <TextField required label="Descripción" multiline rows={4} value={descripcion} onChange={event => setDescripcion(event.target.value)} className={classes.textField} />
                        <Button type="submit" variant="contained" color="primary" className={classes.submitButton}>Enviar</Button>
                    </form>
                </>
            }

            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Su denuncia se envió de forma exitosa.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
