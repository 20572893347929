import React from 'react';
import Box from '@material-ui/core/Box';
import logomejia from '../../assets/images/logomejia.png';
import geovisor from '../../assets/images/geovisor.png';
import fondoheader from '../../assets/images/fondoheader.png';
import { useHistory } from "react-router";

const Header = ({isGeneral}) => {
  let history = useHistory();
  const handleLogoClick = () => {
    history.push('/');
  };
  return (
    <Box display="flex" alignItems="center" bgcolor="#f7f7f7" height="120px">
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        flex="1" 
        onClick={handleLogoClick}
        style={{ cursor: 'pointer' }}
      >
        <img src={logomejia} alt="Logo" style={{ height: '150px' }} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" flex="2">
        <img src={geovisor} alt="GeoVisor Logo" style={{ height: '120px' }} />
      </Box>
      {!isGeneral&&<Box display="flex" justifyContent="flex-end" alignItems="center" flex="1" height="100%">
        <img src={fondoheader} alt="Fondo Header" style={{ height: '100%' }} />
      </Box>
      }
    </Box>
  );
};

export default Header;
