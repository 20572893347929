import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Link, useHistory, useLocation  } from 'react-router-dom';

import catastros from "../assets/portalimages/catastros-8.png";
import deudas from "../assets/portalimages/deudas-8.png";
import geoportal from "../assets/portalimages/geoportal-8.png";
import visorcatastral from "../assets/portalimages/visor_catastral.png";
import iprus from "../assets/portalimages/iprus-8.png";
import icus from "../assets/portalimages/icus-8.png";
import ingresar from "../assets/portalimages/ingresar-8.png";
import patente from "../assets/portalimages/patente-8.png";
import planificacion from "../assets/portalimages/planificacion-8.png";
import tramites from "../assets/portalimages/tramites-8.png";
import logoMejia from "../assets/portalimages/logomejia.png";
import fotoFondo from "../assets/portalimages/fotografia-8.png";
import lineabaja from "../assets/portalimages/lineabaja.png";
import calculadora from "../assets/portalimages/calculadora-8.png";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
    position: 'relative',
    paddingBottom: '80px',
  },
  header: {
    position: 'relative',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  headerImage: {
    width: '100%',
    height: 'auto',
  },
  contentBox: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    maxWidth: '80%',
    margin: '0 auto',
    marginTop: '-280px',
    zIndex: 2,
    position: 'relative',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
  },
  lineabaja: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    textAlign: 'justify',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  gridItem: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    '&.selected': {
      backgroundColor: '#e0e0e0',
      border: '2px solid #333',
    },
  },
  icon: {
    width: 200,
    height: 200,
    marginBottom: theme.spacing(1),
  },
  secondaryIcon: {
    width: 100,
    height: 100,
    marginBottom: theme.spacing(1),
    objectFit: 'contain',
  },
  label: {
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#333',
  },
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
  footerImage: {
    width: '100%',
    display: 'block',
  },
  spacer: {
    marginTop: theme.spacing(3),
    maxHeight: '300px',
    overflowY: 'auto',
  },
  secondaryPaper: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    height: '100%',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
}));

export default function CustomHome() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [showPage, setShowPage] = useState(false);

  const handleItemClick = (column) => {
    setSelectedColumn(column === selectedColumn ? null : column);
  };

  const isItemVisible = (column) => selectedColumn === column;

  const getLinkPath = (label) => `/${label.toLowerCase()}`;

  useEffect(() => {
    const { hash } = location;
    if (hash === "#/searching_value=mobile") {
      history.push("/mobileportal");
    } else if (hash === "#/searching_value=mobile2") {
      history.push("/geoportalv2");
    } else {
      setShowPage(true);
    }
  }, [history, location]);

  if (!showPage) {
    return null;
  }

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <img src={fotoFondo} alt="Imagen de fondo" className={classes.headerImage} />
      </header>
      <Box className={classes.contentBox}>
        <Typography variant="h1" className={classes.title}>
          Portal Ciudadano
        </Typography>
        <img src={lineabaja} alt="Línea decorativa" className={classes.lineabaja} />
        <Typography variant="subtitle1" className={classes.subtitle}>
          Es el sitio web en el que los ciudadanos tienen la posibilidad de acceder a la información sobre la gestión económica y financiera de las Entidades locales, deducida de sus Cuentas Generales rendidas, que incluye tanto las cuentas de la Entidad local principal, así como las de sus entidades dependientes y adscritas.
        </Typography>
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Paper
                className={`${classes.paper} ${selectedColumn === 1 ? 'selected' : ''}`}
                onClick={() => handleItemClick(1)}
              >
                <img src={catastros} alt="Catastros" className={classes.icon} />
              </Paper>
              {isItemVisible(1) && (
                <div className={classes.spacer}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Link to={getLinkPath('consulta_deuda')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={deudas} alt="Deudas" className={classes.secondaryIcon} />
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={getLinkPath('consulta_tramite')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={tramites} alt="Trámites" className={classes.secondaryIcon} />
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={getLinkPath('subservices')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={geoportal} alt="Geoportal" className={classes.secondaryIcon} />  
                        </Paper> 
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={getLinkPath('geoportalv2')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={visorcatastral} alt="Subservicios" className={classes.secondaryIcon} />  
                        </Paper> 
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Paper
                className={`${classes.paper} ${selectedColumn === 2 ? 'selected' : ''}`}
                onClick={() => handleItemClick(2)}
              >
                <img src={planificacion} alt="Planificación" className={classes.icon} />
              </Paper>
              {isItemVisible(2) && (
                <div className={classes.spacer}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Link to={getLinkPath('linea_fabrica')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={iprus} alt="Iprus" className={classes.secondaryIcon} />
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={getLinkPath('icus')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={icus} alt="Icus" className={classes.secondaryIcon} />
                        </Paper>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <Paper
                className={`${classes.paper} ${selectedColumn === 3 ? 'selected' : ''}`}
                onClick={() => handleItemClick(3)}
              >
                <img src={patente} alt="Patente" className={classes.icon} />
              </Paper>
              {isItemVisible(3) && (
                <div className={classes.spacer}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={6}>
                      <Link to={getLinkPath('patente')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={ingresar} alt="Ingresar" className={classes.secondaryIcon} />
                        </Paper>
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Link to={getLinkPath('calculator')}>
                        <Paper className={classes.secondaryPaper}>
                          <img src={calculadora} alt="Calculadora" className={classes.secondaryIcon} />
                        </Paper>
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <footer className={classes.footer}>
        <img src={lineabaja} alt="Línea decorativa inferior" className={classes.footerImage} />
      </footer>
    </div>
  );
}