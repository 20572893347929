import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import ModuleTemplate from './modulos/ModuleTemplate';
import Map from './Charts/Map';
import Home from './Home';
import CustomHome from './CustomHome';
import SubServices from './SubServices.js';
import GeoPortal from './GeoPortal/GeoPortal.js';
import GeoPortalv2 from './GeoPortalv2/GeoPortal.js';
import ConsultaDeuda from './ConsultaDeuda/ConsultaDeuda';
import ConsultaTramite from './ConsultaTramite/ConsultaTramite';
import Denuncia from './Denuncia/CrearDenuncia';
import MobileGeoPortal from './MobileGeoPortal/GeoPortal.js';
import LineaFabrica from './LineaFabrica/LineaFabrica';
import Login from './Login/Login.jsx';
import Register from './Login/Register.jsx';
import RegistroPatente from './Login/RegistroPatente.jsx';
import Icus from './Icus/Icus';
import CompatibilidadSuelo from './CompatibilidadSuelo/CompatibilidadSuelo';
import CompanyProfile from './Login/CompanyProfile.jsx';
import ForgotPasswordComponent from './Login/ForgotPasswordComponet.jsx';
import ActivityRegister from './Login/ActivityRegister.jsx';
import ClosePatent from './Login/ClosePatent.jsx';
import Calculator from './Login/Calculator.jsx';
import CheckUserState from './Login/CheckUserState.jsx';
class Router extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                <Route path='/mobileportal' exact component={MobileGeoPortal} />
                <Route path='/geoportal' exact component={GeoPortal} />
                <Route path='/geoportalv2/:componentId?' exact component={GeoPortalv2} />
                    <ModuleTemplate>
                        <Route component={({ match }) =>
                            <div>
                                <Route path='/' exact component={CustomHome} />
                                <Route path='/subservices' exact component={SubServices} />
                                <Route path='/mapa' exact component={Map} />
                                <Route path='/consulta_deuda' exact component={ConsultaDeuda} />
                                <Route path='/consulta_tramite' exact component={ConsultaTramite} />
                                <Route path='/crear_denuncia' exact component={Denuncia} />
                                <Route path='/linea_fabrica' exact component={LineaFabrica} />
                                <Route path='/patente' exact component={Login} />
                                <Route path='/close_patent' exact component={ClosePatent} />
                                <Route path='/register' exact component={Register} />
                                <Route path='/company_profile' exact component={CompanyProfile} />
                                <Route path='/check_user_state' exact component={CheckUserState} />
                                <Route path='/forgot_password' exact component={ForgotPasswordComponent} />
                                <Route path='/activity_register' exact component={ActivityRegister} />
                                <Route path='/registro_patente' exact component={RegistroPatente} />
                                <Route path='/icus' exact component={Icus} />
                                <Route path='/calculator' exact component={Calculator} />
                                <Route path='/icus/predio/:predio/:id' exact component={CompatibilidadSuelo} />
                            </div>
                        } />
                    </ModuleTemplate>
                    
                </Switch>
            </BrowserRouter>
        );
    }

}
export default Router;
