import React, { useState, useEffect } from 'react';
import { Modal, Fade, Backdrop, IconButton, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import PatenteService from '../../apis/PatenteService';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    width: '50%',
    borderRadius: theme.shape.borderRadius,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: '100%',
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const NuevaCuenta = ({ open, handleClose, handleAddAccount, handleUpdateAccount, enableEdition, idEstablecimiento, existingAccount, userName, idPatente }) => {
  const classes = useStyles();

  const [valor, setValor] = useState(0);
  const [periodo, setPeriodo] = useState('');
  const [tipo, setTipo] = useState('');
  const [descripcionCatalogo, setDescripcionCatalogo] = useState('');

  const [periodoOptions, setPeriodoOptions] = useState([]);
  const [tipoOptions, setTipoOptions] = useState([]);
  const [descripcionOptions, setDescripcionOptions] = useState([]);

  useEffect(() => {
    const fetchSelectOptions = async () => {
      try {
        const [periodoResponse, tipoResponse] = await Promise.all([
          PatenteService.listPeriods(),
          PatenteService.listCatalogs()
        ]);
        setPeriodoOptions(periodoResponse.data);
        setTipoOptions(tipoResponse.data);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };

    fetchSelectOptions();
  }, []);

  useEffect(() => {
    if (existingAccount) {
      setValor(existingAccount.valor);
      setPeriodo(existingAccount.idPeriodo);
      setTipo(existingAccount.idTipoCatalogo);
      setDescripcionCatalogo(existingAccount.idDescripcionCatalogo);
    }
  }, [existingAccount]);

  const handleValorChange = (event) => {
    setValor(event.target.value);
  };

  const handlePeriodoChange = (event) => {
    setPeriodo(event.target.value);
  };

  const handleTipoChange = async (event) => {
    const selectedTipo = event.target.value;
    setTipo(selectedTipo);
    try {
      const response = await PatenteService.listCatalogDescription(selectedTipo);
      setDescripcionOptions(response.data);
      setDescripcionCatalogo(''); // Reset descripción when tipo changes
    } catch (error) {
      console.error('Error fetching description options:', error);
    }
  };

  const handleDescripcionCatalogoChange = (event) => {
    setDescripcionCatalogo(event.target.value);
  };

  const handleSubmit = () => {
    const newAccount = {
      idEstablecimiento,
      valor,
      idPeriodo: periodo,
      idTipoCatalogo: tipo,
      idDescripcionCatalogo: descripcionCatalogo,
      usuario: userName,
      idPatente
    };
    if (existingAccount && existingAccount.idCuentas) {
      newAccount["idCuentas"] = existingAccount.idCuentas;
    }
    if (existingAccount) {
      handleUpdateAccount(newAccount);
    } else {
      handleAddAccount(newAccount);
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {existingAccount ? 'Modificar Cuenta' : 'Nueva Cuenta'}
          </Typography>
          <form>
            <TextField variant="outlined" margin="normal" required fullWidth id="valor" label="Valor" name="valor" value={valor} onChange={handleValorChange} className={classes.textField} />
            <FormControl variant="outlined" className={classes.formControl} required>
              <InputLabel id="periodo-label">Periodo</InputLabel>
              <Select labelId="periodo-label" id="periodo" name="periodo" value={periodo} onChange={handlePeriodoChange} label="Periodo">
                {periodoOptions.map((option) => (
                  <MenuItem key={option.idPeriodo} value={option.idPeriodo}>
                    {`${option.fechaInicio.replace('Z', '')} - ${option.fechaFin.replace('Z', '')}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} required>
              <InputLabel id="tipo-label">Tipo</InputLabel>
              <Select labelId="tipo-label" id="tipo" name="tipo" value={tipo} onChange={handleTipoChange} label="Tipo">
                {tipoOptions.map((option) => (
                  <MenuItem key={option.catId} value={option.catId}>
                    {option.catNombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl} required>
              <InputLabel id="descripcion-catalogo-label">Descripción</InputLabel>
              <Select labelId="descripcion-catalogo-label" id="descripcionCatalogo" name="descripcionCatalogo" value={descripcionCatalogo} onChange={handleDescripcionCatalogoChange} label="Descripción">
                {descripcionOptions.map((option) => (
                  <MenuItem key={option.catId} value={option.catId}>
                    {option.catNombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {enableEdition && (
              <Button type="button" variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
                {existingAccount ? 'Guardar Cambios' : 'Agregar'}
              </Button>
            )}
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default NuevaCuenta;
