import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, Typography, Paper, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import PatenteService from '../apis/PatenteService';

import { Breadcrumbs, Link } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch, Link as RouterLink } from 'react-router-dom';

import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { NotificationManager } from 'react-notifications';

import { connect } from 'react-redux';
import actualizarJson from '../../redux/actions/actualizarJson';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 0),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  form: {
    width: '100%',
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
  textField: {
    '& .MuiInputBase-root': {},
    '& .MuiInputLabel-root': {
      width: 'calc(100% - 24px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'absolute',
    },
    '& .MuiOutlinedInput-input': {},
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
}));

const RegistroPatente = ({ json, actualizarJson }) => {
  const classes = useStyles();
  let history = useHistory();

  // Estados para todos los campos
  const [idPropietario, setIdPropietario] = useState('');
  const [idRepresentanteLegal, setIdRepresentanteLegal] = useState('');
  const [fechaInicioActividad, setFechaInicioActividad] = useState('2024-01-01');
  const [establecimientoComercial, setEstablecimientoComercial] = useState('');
  const [numeroEstablecimiento, setNumeroEstablecimiento] = useState('');
  const [callePrincipal, setCallePrincipal] = useState('');
  const [numeroEdificacion, setNumeroEdificacion] = useState('');
  const [calleSecundaria, setCalleSecundaria] = useState('');
  const [referencia, setReferencia] = useState('');
  const [telefono, setTelefono] = useState('');
  const [celular, setCelular] = useState('');
  const [correoElectronico, setCorreoElectronico] = useState('');
  const [cedula, setCedula] = useState('');
  const [estado, setEstado] = useState(1); // Estado por defecto "Activo"
  const [observacion, setObservacion] = useState('');
  const [enLiquidacion, setEnLiquidacion] = useState(0); // Liquidación por defecto "No"
  const [fechaRegistro, setFechaRegistro] = useState('2024-03-21');
  const [fechaInicioActividadCanton, setFechaInicioActividadCanton] = useState('2024-01-01');
  const [nombrecompleto, setNombreCompleto] = useState('');

  const initServices = async () => {
    const { loginInfo } = json;
    setCedula(loginInfo.idPropietario);
    setNombreCompleto(loginInfo.nombres + ' ' + loginInfo.apellidos);
  }

  useEffect(() => {
    initServices();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      idPropietario: cedula,
      fechaInicioActividad,
      estado,
      usuario: "globalgad",
      observacion,
      enLiquidacion,
      fechaInicioActividadCanton
    };

    const patentService = await PatenteService;
    patentService.createPatent(formData).then(
      result => {
        NotificationManager.success("Ingreso Exitoso.", "", 5000, () => { });
        if (result.data.status.codigo === "001") {
          history.push("/company_profile");
        } else NotificationManager.error("Error en su ingreso por favor intente más tarde.", "", 5000, () => { });

      }, error => {
        NotificationManager.error("Error en su ingreso por favor intente más tarde.", "", 5000, () => { });
      }
    );

  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsContainer}>
        <Link component={RouterLink} to="/company_profile" color="inherit">
          <HomeIcon className={classes.linkIcon} />
          Inicio
        </Link>
        <Typography color="textPrimary">
          <AssignmentIcon className={classes.linkIcon} />
          Registro de Patentes
        </Typography>
      </Breadcrumbs>
      <Grid container className={classes.root}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Registro de Patente
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" margin="normal" required fullWidth id="idPropietario" label="Propietario" name="idPropietario" value={nombrecompleto} onChange={e => setIdPropietario(e.target.value)} autoComplete="idPropietario" className={classes.textField} readOnly />
                {/*<TextField variant="outlined" margin="normal" required fullWidth id="idRepresentanteLegal" label="Representante" name="idRepresentanteLegal" value={idRepresentanteLegal} onChange={e => setIdRepresentanteLegal(e.target.value)} autoComplete="idRepresentanteLegal" className={classes.textField} />*/}
                <TextField id="fechaInicioActividad" label="Fecha de inicio de actividades" type="date" value={fechaInicioActividad} onChange={e => setFechaInicioActividad(e.target.value)} InputLabelProps={{ shrink: true, }} variant="outlined" margin="normal" required fullWidth className={classes.textField} />
                <FormControl variant="outlined" className={classes.formControl} required>
                  <InputLabel id="estado-label">Estado</InputLabel>
                  <Select labelId="estado-label" id="estado" value={estado} onChange={(e) => setEstado(e.target.value)} label="Estado" className={classes.textField}>
                    <MenuItem value={1}>Activo</MenuItem>
                    <MenuItem value={0}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" margin="normal" required fullWidth id="observacion" label="Observación" name="observacion" value={observacion} onChange={e => setObservacion(e.target.value)} autoComplete="observacion" multiline rows={4} className={classes.textField} />
                <FormControl variant="outlined" className={classes.formControl} required>
                  <InputLabel id="enLiquidacion-label">En Liquidación</InputLabel>
                  <Select labelId="enLiquidacion-label" id="enLiquidacion" value={enLiquidacion} onChange={(e) => setEnLiquidacion(e.target.value)} label="En Liquidación" className={classes.textField}>
                    <MenuItem value={1}>Sí</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="fechaInicioActividadCanton" label="Inicio Actividades Cantón" type="date" value={fechaInicioActividadCanton} onChange={e => setFechaInicioActividadCanton(e.target.value)} InputLabelProps={{ shrink: true, }} variant="outlined" margin="normal" required fullWidth className={classes.textField} />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
              Registrar Patente
            </Button>
          </form>
        </Paper>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    json: state.generalJson.json
  };
}
const mapDispatchToProps = {
  actualizarJson
}
export default connect(mapStateToProps, mapDispatchToProps)(RegistroPatente);
