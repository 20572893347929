import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fade, Grid, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, Modal, Backdrop, IconButton } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import actualizarJson from '../../redux/actions/actualizarJson';
import PatenteService from '../apis/PatenteService';
import { NotificationManager } from 'react-notifications';

import NuevoEstablecimiento from './components/NuevoEstablecimiento';
import NuevaPatente from './components/NuevaPatente';
import NuevaActividad from './components/NuevaActividad';
import NuevaCuenta from './components/NuevaCuenta';
import ManageDocuments from './components/ManageDocuments';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3),
    maxWidth: 800
  },
  icon: {
    fontSize: '6rem',
    color: theme.palette.primary.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  text: {
    margin: theme.spacing(1, 0),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
    zIndex: theme.zIndex.modal + 1,
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '90%',
    maxHeight: '90vh',
    overflowY: 'auto',
  },
  textField: {
    '& .MuiInputBase-root': {},
    '& .MuiInputLabel-root': {
      width: 'calc(100% - 24px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      position: 'absolute',
    },
    '& .MuiOutlinedInput-input': {},
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  details: {
    width: '100%',
  },
  gridItem: {
    padding: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  row: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#e9e9e9'
    }
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const CompanyProfile = ({ json, actualizarJson }) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [openPatentModal, setOpenPatentModal] = useState(false);
  const [openActivityModal, setOpenActivityModal] = useState(false);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [openInitialModal, setOpenInitialModal] = useState(false);

  const [patentList, setPatentList] = useState([]);
  const [establishments, setEstablishments] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [usName,setUsName]=useState("");
  const [patentId, setPatentId] = useState(null);

  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [activityOptions, setActivityOptions] = useState([]);

  const [enableEdition, setEnableEdition] = useState(0); // 0: Read-only, 1: Editable

  const [pendingActions, setPendingActions] = useState({
    establishments: [],
    patents: [],
    activities: [],
    accounts: [],
  });

  const [existingPatent, setExistingPatent] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const listEstablishments = async () => {
    const patentService = await PatenteService;
    patentService.listEstablishment(patentId).then(result => {
      setEstablishments(result.data);
    }, error => {
      console.error('Error fetching establishments:', error);
    });
  };

  const listActivitiesAndAccounts = async (establishmentId) => {
    const patentService = await PatenteService;
    let activities = [];
    let accounts = [];

    try {
      const activitiesResponse = await patentService.listActivities(establishmentId);
      if (Array.isArray(activitiesResponse.data)) {
        activities = activitiesResponse.data;
      }
    } catch (error) {
      console.error('Error fetching activities:', error);
    }

    try {
      const accountsResponse = await patentService.listAccounts(patentId);
      if (Array.isArray(accountsResponse.data)) {
        accounts = accountsResponse.data;
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
    console.log("el listado de actividades es", activities);
    setSelectedEstablishment(prev => ({
      ...prev,
      activities: activities,
      accounts: accounts,
    }));
  };
  const generateUsername = (completeUsername) => {
    // Eliminar espacios en blanco y caracteres especiales
    const sanitizedInput = completeUsername.replace(/[^a-zA-Z\s]/g, '').trim();

    // Separar el nombre en palabras
    const words = sanitizedInput.split(/\s+/);

    // Generar el nombre de usuario basado en la cantidad de palabras
    let username = '';
    if (words.length === 1) {
        username = words[0].toLowerCase();
    } else if (words.length === 2) {
        username = `${words[0][0].toLowerCase()}${words[1].toLowerCase()}`;
    } else {
        username = `${words[0][0].toLowerCase()}${words[1][0].toLowerCase()}${words.slice(2).join('').toLowerCase()}`;
    }

    return username;
};

  const initServices = async () => {
    const patentService = await PatenteService;
    try {
      const { loginInfo } = json;
      let patentListTmp = [];
      let userProfileTmp = [];

      try {
        patentListTmp = await patentService.patentList(loginInfo.cedula);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn('Patent list not found (404).');
        } else {
          console.error('Error fetching patent list:', error);
        }
      }

      try {
        userProfileTmp = await patentService.getUserProfile(loginInfo.cedula);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn('User profile not found (404).');
        } else {
          console.error('Error fetching user profile:', error);
        }
      }

      if (userProfileTmp.data && userProfileTmp.data.length > 0) {
        setUserProfile(userProfileTmp.data[0]);
        const usrTmp=userProfileTmp.data[0];
        setUsName(generateUsername(usrTmp["nombres"]+" "+usrTmp["apellidos"]));
        
      } else {
        console.warn('No user profile data available.');
      }

      if (patentListTmp.data && patentListTmp.data.length > 0) {
        setPatentList(patentListTmp.data);
        setExistingPatent(patentListTmp.data); // Set the existing patent
        const { idPatente } = patentListTmp.data[0];
        setPatentId(idPatente);
        let generalJson = { ...json };
        generalJson["patenteId"] = idPatente;
        generalJson["propietary"] = userProfileTmp.data[0];
        actualizarJson({ generalJson });
      } else {
        console.warn('No patent list data available.');
      }
      if (patentId) {
        listEstablishments();
      }
    } catch (error) {
      console.error('Error initializing services:', error);
    }
  };

  const handleAddEstablishment = (newEstablishment) => {
    setPendingActions(prev => ({
      ...prev,
      establishments: [...prev.establishments, newEstablishment],
    }));
    setEstablishments(prev => [...prev, newEstablishment]);
  };

  const handleAddPatent = (newPatent) => {
    setPendingActions(prev => ({
      ...prev,
      patents: [...prev.patents, newPatent],
    }));
    setPatentList(prev => [...prev, newPatent]);
  };

  const handleAddActivity = (newActivity) => {
    setPendingActions(prev => ({
      ...prev,
      activities: [...prev.activities, newActivity],
    }));
    setSelectedEstablishment(prev => ({
      ...prev,
      activities: [...(prev.activities || []), newActivity],
    }));
  };

  const handleAddAccount = (newAccount) => {
    setPendingActions(prev => ({
      ...prev,
      accounts: [...prev.accounts, newAccount],
    }));
    setSelectedEstablishment(prev => ({
      ...prev,
      accounts: [...(prev.accounts || []), newAccount],
    }));
  };

  const handleUpdatePatent = (updatedPatent) => {
    // Add the updated patent to the pendingActions
    setPendingActions(prev => ({
      ...prev,
      patents: [...prev.patents, updatedPatent],
    }));
    // Update the patent list with the updated patent
    setPatentList(prev => prev.map(patent => patent.idPatente === updatedPatent.idPatente ? updatedPatent : patent));
  };

  const handleUpdateActivity = (updatedActivity) => {
    // Add the updated activity to the pendingActions
    setPendingActions(prev => ({
      ...prev,
      activities: [...prev.activities, updatedActivity],
    }));
    // Update the activities list of the selected establishment
    setSelectedEstablishment(prev => ({
      ...prev,
      activities: prev.activities.map(activity => activity.idActividad === updatedActivity.idActividad ? updatedActivity : activity),
    }));
  };

  const handleUpdateAccount = (updatedAccount) => {
    // Add the updated account to the pendingActions
    setPendingActions(prev => ({
      ...prev,
      accounts: [...prev.accounts, updatedAccount],
    }));
    // Update the accounts list of the selected establishment
    setSelectedEstablishment(prev => ({
      ...prev,
      accounts: prev.accounts.map(account => account.idCuenta === updatedAccount.idCuenta ? updatedAccount : account),
    }));
  };

  const handleUpdateEstablishment = (updatedEstablishment) => {
    // Add the updated establishment to the pendingActions
    setPendingActions(prev => ({
      ...prev,
      establishments: [...prev.establishments, updatedEstablishment],
    }));
    // Update the establishments list
    setEstablishments(prev => prev.map(est => est.idEstablecimiento === updatedEstablishment.idEstablecimiento ? updatedEstablishment : est));
  };

  const handleSave = async () => {
    const patentService = await PatenteService;

    try {
      await Promise.all(pendingActions.establishments.map(async (establishment) => {
        if (establishment.idEstablecimiento) {
          await patentService.updateEstablishment(establishment);
        } else {
          await patentService.createEstablishment(establishment);
        }
      }));

      await Promise.all(pendingActions.patents.map(async (patent) => {
        if (patent.idPatente) {
          await patentService.updatePatent(patent);
        } else {
          await patentService.createPatent(patent);
        }
      }));

      await Promise.all(pendingActions.activities.map(async (activity) => {
        console.log("actividad a revisar es", activity);
        if (activity.idEstablecimientoActividad) {
          await patentService.updateActivity(activity);
        } else {
          await patentService.createEstablishmentActivity(activity);
        }
      }));

      await Promise.all(pendingActions.accounts.map(async (account) => {
        if (account.idCuentas) {
          await patentService.updateAccount(account);
        } else {
          await patentService.newAccount(account);
        }
      }));
      if (patentId) {
        const objRegister = {
          idPatente: patentId,
          usuario: 'echiza',
          observacion: 'MODIFICACION DATOS PATENTE'
        };
        await patentService.eventRegister(objRegister);
      }

      await initServices();
      NotificationManager.success('Cambios guardados exitosamente.', '', 5000);
      setPendingActions({
        establishments: [],
        patents: [],
        activities: [],
        accounts: [],
      }); // Recargar los datos después de guardar
    } catch (error) {
      console.error('Error saving data:', error);
      NotificationManager.error('Error guardando los cambios.', '', 5000);
    }
  };


  const handleRowClick = async (establishment) => {
    setSelectedEstablishment(establishment);
    await listActivitiesAndAccounts(establishment.idEstablecimiento); // Llamar a la función para obtener actividades y cuentas
    setOpenInitialModal(true); // Abrir la ventana modal inicial para actividades/cuentas
  };

  const handleEditPatent = () => {
    setOpenPatentModal(true);
  };

  const handleEditActivity = (activity) => {
    console.log("en el handle", activity);
    setSelectedActivity(activity);
    setOpenActivityModal(true);
  };

  const handleEditAccount = (account) => {
    setSelectedAccount(account);
    setOpenAccountModal(true);
  };

  useEffect(() => {
    initServices();
  }, []);

  useEffect(() => {
    if (patentId) {
      listEstablishments();
    }
  }, [patentId]);

  useEffect(() => {
    if (json) {
      setEnableEdition(json.edit);
    }
  }, [json]);

  return (
    <>
      <Grid container justify="center">
        <Paper className={classes.root} elevation={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <AccountCircleIcon className={classes.icon} />
            <Typography component="h1" variant="h5">
              Datos del Contribuyente
            </Typography>
          </Box>
          <Grid container className={classes.details} spacing={2}>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <Typography variant="subtitle1" className={classes.text}>
                Razón Social: {`${userProfile.nombres} ${userProfile.apellidos}`}
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                RUC: {userProfile.cedula}
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                Obligado a llevar Contabilidad: {userProfile.obligadoLlevarCont}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <Typography variant="subtitle1" className={classes.text}>
                Correo electrónico: {userProfile.mail}
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                Tel&eacute;fono: {userProfile.telefono}
              </Typography>
              <Typography variant="subtitle1" className={classes.text}>
                Direcci&oacute;n: {userProfile.direccion}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="textSecondary" align="left" className={classes.divider}>
            Recuerde: Mantenga sus datos actualizados.
          </Typography>
        </Paper>
      </Grid>
      {(enableEdition === 1 || !patentId) && (
        <Button variant="contained" color="primary" startIcon={<AddIcon />} style={{ marginTop: 16 }} onClick={() => setOpenPatentModal(true)}>
          Nueva Patente
        </Button>
      )}
      {(enableEdition === 1 || !patentId) && (
        <Button variant="contained" color="primary" style={{ marginTop: 16, marginLeft: 16 }} onClick={handleSave}>
          Guardar
        </Button>
      )}
      {enableEdition === 1 && patentId && (
        <Button variant="contained" color="secondary" startIcon={<EditIcon />} style={{ marginTop: 16, marginLeft: 16 }} onClick={handleEditPatent}>
          Modificar Patente
        </Button>
      )}
      <Typography variant="h6" style={{ margin: 16 }}>PATENTES</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Num. Patente</TableCell>
              <TableCell>Fecha Registro</TableCell>
              <TableCell>Fecha Inicio Actividad</TableCell>
              <TableCell>Fecha Inicio Actividad Cantón</TableCell>
              <TableCell>Fecha Cierre</TableCell>
              <TableCell>En Liquidación</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Observación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patentList.map((row, index) => (
              <TableRow key={`${row.idPatente}-${index}`}>
                <TableCell>{row.idPatente}</TableCell>
                <TableCell>{row.fechaRegistro}</TableCell>
                <TableCell>{row.fechaInicioActividad}</TableCell>
                <TableCell>{row.fechaInicioActividadCanton}</TableCell>
                <TableCell>{row.fechaCierre}</TableCell>
                <TableCell>{row.enLiquidacion === 0 ? 'No' : 'Sí'}</TableCell>
                <TableCell>{row.estado === 1 ? 'Activo' : 'Inactivo'}</TableCell>
                <TableCell>{row.observacion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {enableEdition === 1 && patentId && (
        <Button variant="contained" color="primary" startIcon={<AddIcon />} style={{ marginTop: 16 }} onClick={() => setOpenModal(true)}>
          Nuevo Establecimiento
        </Button>
      )}
      <br />
      <Typography variant="h6" style={{ margin: 16 }}>ESTABLECIMIENTOS</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Num. Establecimiento</TableCell>
              <TableCell>Calle Principal</TableCell>
              <TableCell>Calle Secundaria</TableCell>
              <TableCell>Num. Casa</TableCell>
              <TableCell>Barrio</TableCell>
              <TableCell>Catálogo Matriz</TableCell>
              <TableCell>Correo Electrónico</TableCell>
              <TableCell>Nombre Comercial</TableCell>
              <TableCell>Número de Establecimiento</TableCell>
              <TableCell>Parroquia</TableCell>
              <TableCell>Estado</TableCell>
              {enableEdition === 1 && <TableCell>Acciones</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {establishments.map((row, index) => (
              <TableRow key={`${row.idEstablecimiento}-${index}`} className={classes.row} onClick={() => handleRowClick(row)}>
                <TableCell>{row.idEstablecimiento}</TableCell>
                <TableCell>{row.callePrincipal}</TableCell>
                <TableCell>{row.calleSecundaria}</TableCell>
                <TableCell>{row.numeroEdificacion}</TableCell>
                <TableCell>{row.idBarrio}</TableCell>
                <TableCell>{row.catalogoMatriz}</TableCell>
                <TableCell>{row.correoElectronico}</TableCell>
                <TableCell>{row.establecimientoComercial}</TableCell>
                <TableCell>{row.numeroEstablecimiento}</TableCell>
                <TableCell>{row.parroquia}</TableCell>
                <TableCell>{row.estado === 1 ? 'Activo' : row.estado === 0?'Inactivo':row.estado}</TableCell>
                {enableEdition === 1 && (
                  <TableCell>
                    <Button
                      startIcon={<EditIcon />}
                      onClick={(e) => {
                        e.stopPropagation(); // Detener la propagación del evento
                        setOpenModal(true);
                        setSelectedEstablishment(row); // Asignar el establecimiento seleccionado
                      }}
                    >
                      Editar
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <NuevoEstablecimiento open={openModal} handleClose={() => setOpenModal(false)} handleAddEstablishment={handleAddEstablishment} handleUpdateEstablishment={handleUpdateEstablishment} enableEdition={enableEdition} patentId={patentId} userName={usName} existingEstablishment={selectedEstablishment}/>
      <NuevaPatente open={openPatentModal} handleClose={() => setOpenPatentModal(false)} handleAddPatent={handleAddPatent} handleUpdatePatent={handleUpdatePatent} enableEdition={enableEdition} userProfile={userProfile} existingPatent={existingPatent} userName={usName} />
      <Modal open={openInitialModal} onClose={() => setOpenInitialModal(false)} className={classes.modal} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
        <Fade in={openInitialModal}>
          <div className={classes.modalPaper}>
            <IconButton aria-label="close" className={classes.closeButton} onClick={() => setOpenInitialModal(false)}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" gutterBottom>
              Actividades y Cuentas
            </Typography>
            {selectedEstablishment && (
              <>
                <Typography variant="subtitle1">
                  Nombre Comercial: {selectedEstablishment.establecimientoComercial}
                </Typography>
                <Typography variant="subtitle1">
                  Número de Establecimiento: {selectedEstablishment.numeroEstablecimiento}
                </Typography>
                <Typography variant="subtitle1">
                  Calle Principal: {selectedEstablishment.callePrincipal}
                </Typography>
                <Typography variant="subtitle1">
                  Calle Secundaria: {selectedEstablishment.calleSecundaria}
                </Typography>
                <Typography variant="subtitle1">
                  Número de Edificación: {selectedEstablishment.numeroEdificacion}
                </Typography>
                {enableEdition === 1 && (
                  <>
                    {userProfile.obligadoLlevarCont === "No" ?
                      < Button variant="contained" color="primary" startIcon={<AddIcon />} className={classes.button} onClick={() => setOpenActivityModal(true)}>
                        Nueva Actividad
                      </Button>
                      :
                      <Button variant="contained" color="primary" startIcon={<AddIcon />} className={classes.button} onClick={() => setOpenAccountModal(true)}>
                        Nueva Cuenta
                      </Button>
                    }
                  </>
                )}
                <Typography variant="h6" style={{ marginTop: 16 }}>Actividades</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Actividad</TableCell>
                        <TableCell>Base Imponible</TableCell>
                        <TableCell>Estado</TableCell>
                        {enableEdition === 1 && <TableCell>Acciones</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedEstablishment.activities && selectedEstablishment.activities.map((activity, index) => (
                        <TableRow key={`${activity.idActividad}-${index}`}>
                          <TableCell>{activity.actividad}</TableCell>
                          <TableCell>{activity.baseImponible}</TableCell>
                          <TableCell>{activity.estado === 1 ? 'Activo' : activity.estado === 0?'Inactivo':activity.estado}</TableCell>
                          {enableEdition === 1 && (
                            <TableCell>
                              <Button startIcon={<EditIcon />} onClick={() => handleEditActivity(activity)}>
                                Editar
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography variant="h6" style={{ marginTop: 16 }}>Cuentas</Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Valor</TableCell>
                        <TableCell>Periodo</TableCell>
                        {enableEdition === 1 && <TableCell>Acciones</TableCell>}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedEstablishment.accounts && selectedEstablishment.accounts.map((account, index) => (
                        <TableRow key={`${account.idCuenta}-${index}`}>
                          <TableCell>{account.descripcion}</TableCell>
                          <TableCell>{account.valor}</TableCell>
                          <TableCell>{account.periodo}</TableCell>
                          {enableEdition === 1 && (
                            <TableCell>
                              <Button startIcon={<EditIcon />} onClick={() => handleEditAccount(account)}>
                                Editar
                              </Button>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <NuevaActividad open={openActivityModal} handleClose={() => setOpenActivityModal(false)} handleAddActivity={handleAddActivity} handleUpdateActivity={handleUpdateActivity} enableEdition={enableEdition} idEstablecimiento={selectedEstablishment.idEstablecimiento} existingActivity={selectedActivity} userName={usName} />
                <NuevaCuenta open={openAccountModal} handleClose={() => setOpenAccountModal(false)} handleAddAccount={handleAddAccount} handleUpdateAccount={handleUpdateAccount} enableEdition={enableEdition} idEstablecimiento={selectedEstablishment.idEstablecimiento} existingAccount={selectedAccount} userName={usName} idPatente={patentId}/>
              </>
            )}
          </div>
        </Fade>
      </Modal >
      {patentId && <ManageDocuments patentId={patentId} showButtons={enableEdition === 1} userName={usName} />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    json: state.generalJson.json
  };
}

const mapDispatchToProps = {
  actualizarJson
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
