import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import SvgIcon from "@material-ui/core/SvgIcon";
import icon from "../css/kmz.svg";
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function Download(props) {
    const classes = useStyles();
    return (
        <React.Fragment>
            <IconButton aria-label="Pdf" className={classes.margin} size="large" onClick={() => {
                props.customparameters.pdfDownload();
            }}>
                <PictureAsPdfIcon />
            </IconButton>
            Descargar Pdf
        </React.Fragment>
    );
}