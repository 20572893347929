import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, Backdrop,
    Fade, FormControl, InputLabel, Select, MenuItem, Button, Grid, IconButton, Typography, Box, TextField
} from '@material-ui/core';

import { Breadcrumbs, Link } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch, Link as RouterLink } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import PatenteService from '../apis/PatenteService';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        marginTop: theme.spacing(4),
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
    addButton: {
        margin: theme.spacing(4),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    modalPaper: {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        outline: 'none',
        width: '50%',
        borderRadius: theme.shape.borderRadius,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    establishmentInfo: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
    },
    infoHeader: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    infoItem: {
        marginBottom: theme.spacing(1),
    },
}));

const ActivityRegister = ({ json }) => {
    const classes = useStyles();
    const [institution, setInstitution] = useState({
        idPatente: 1842,
        idBarrio: 1,
        establecimientoComercial: "peubas nombre comercial",
        numeroEstablecimiento: 45,
        callePrincipal: "Pruebas Calle Principal",
        numeroEdificacion: 45,
        calleSecundaria: "Calle secundaria"
    });
    const [openAccountModal, setOpenAccountModal] = useState(false);
    const [accountValue, setAccountValue] = useState('');
    const [catalogs, setCatalogs] = useState([]);
    const [catalogDesc, setCatalogDesc] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [selectedCatalog, setSelectedCatalog] = useState('');
    const [selectedCatalogDesc, setSelectedCatalogDesc] = useState('');
    const [selectedPeriod, setSelectedPeriod] = useState('');
    const [establishment, setEstablishment] = useState({});
    const [activities, setActivities] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [activityList, setActivityList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [activityOptions, setActivityOptions] = useState([]);
    const [valor, setValor] = useState(0);
    const [selectedActivity, setSelectedActivity] = useState('');
    const [accountList, setAccountList] = useState([]);
    const [propietary, setPropietary] = useState({});

    const handleOpenAccountModal = () => {
        setOpenAccountModal(true);
    };

    const handleCloseAccountModal = () => {
        setOpenAccountModal(false);
    };

    const handleCatalogChange = async (event) => {
        const selectedCatalogName = event.target.value;
        setSelectedCatalog(selectedCatalogName);
        await getCataloDescs(selectedCatalogName);
    };

    const handleCatalogDescChange = (event) => {
        setSelectedCatalogDesc(event.target.value);
    };

    const handlePeriodChange = (event) => {
        setSelectedPeriod(event.target.value);
    };

    const handleSubmitAccount = async () => {
        const { patenteId } = json;
        const payload = {
            idPatente: patenteId,
            idTipoCatalogo: catalogs.find(catalog => catalog.catNombre === selectedCatalog).catId,
            idDescripcionCatalogo: selectedCatalogDesc,
            valor: accountValue,
            idPeriodo: selectedPeriod,
            usuario: 'echiza'
        };
        const patentService = await PatenteService;

        try {

            const response = await patentService.newAccount(JSON.stringify(payload));
            handleCloseAccountModal();
            initServices();
        } catch (error) {
            console.error('Error al enviar datos:', error);
        }
    };

    const handleSearchChange = async (event, value) => {
        setSearchText(value);
        if (value.length >= 3) { // Se ha reducido el número de caracteres para que inicie la búsqueda
            try {
                const patentService = await PatenteService;
                const response = await patentService.listActivitiesSearch(value);
                const data = response.data;
                if (Array.isArray(data)) {
                    const filteredData = data.filter(activity =>
                        activity.actividad.toLowerCase().includes(value.toLowerCase()) ||
                        activity.ciu.toLowerCase().includes(value.toLowerCase())
                    );
                    setActivityOptions(filteredData);
                } else {
                    setActivityOptions([]);
                }
            } catch (error) {
                console.error('Error fetching activities:', error);
                setActivityOptions([]);
            }
        } else {
            setActivityOptions([]);
        }
    };

    const getCataloDescs = async (name) => {
        const patentService = await PatenteService;
        const catalogdescListTmp = await patentService.listCatalogDescription(name);
        setCatalogDesc(catalogdescListTmp.data);
    };

    const initServices = async () => {
        try {
            const patentService = await PatenteService;
            const establishmentTmp = json.establishment;
            setPropietary(json.propietary);
            
            let listAccountsTmp;
            try {
                const { patenteId } = json;
                listAccountsTmp = await patentService.listAccounts(patenteId);
                setAccountList(listAccountsTmp.data);
            } catch (error) {
                console.error('Error al listar cuentas:', error);
                listAccountsTmp = { data: [] };
            }

            let activityListTmp;
            try {
                activityListTmp = await patentService.listActivities(establishmentTmp.idEstablecimiento);
                setActivityList(activityListTmp.data);
            } catch (error) {
                console.error('Error al listar actividades:', error);
                activityListTmp = { data: [] };
            }

            setEstablishment(json.establishment);
            try {
                const [catalogsTmp, periodsTmp] = await Promise.all([
                    patentService.listCatalogs(),
                    patentService.listPeriods()
                ]);
                setCatalogs(catalogsTmp.data);
                setPeriods(periodsTmp.data);
            } catch (error) {
                console.error('Error al cargar los catálogos y periodos:', error);
                setCatalogs([]);
                setPeriods([]);
            }
        } catch (error) {
            console.error('Error al inicializar los servicios:', error);
        }
    };

    useEffect(() => {
        initServices();
    }, []);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleValorChange = (event) => {
        setValor(event.target.value);
    };

    const handleSelectActivity = (event, newValue) => {
        setSelectedActivity(newValue);
    };

    const handleSubmit = async () => {
        const finalActivity = {
            "idEstablecimiento": establishment.idEstablecimiento,
            "idActividad": selectedActivity.idActividad,
            "usuario": 'echiza',
            "estado": 1,
            "baseImponible": valor
        };
        const patentService = await PatenteService;
        patentService.createEstablishmentActivity(finalActivity).then(result => {
            initServices();
        }, error => {
            console.error('Error al crear actividad:', error);
        });
        handleClose();
    };

    const handleDelete = (id) => {
        setActivities(activities.filter((activity) => activity.id !== id));
    };

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbsContainer}>
                <Link component={RouterLink} to="/company_profile" color="inherit">
                    <HomeIcon className={classes.linkIcon} />
                    Inicio
                </Link>
                <Typography color="textPrimary">
                    <AssignmentIcon className={classes.linkIcon} />
                    Establecimiento
                </Typography>
            </Breadcrumbs>
            <Paper className={classes.establishmentInfo}>
                <Typography variant="h6" className={classes.infoHeader}>Información del Establecimiento</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.infoItem}><strong>Nombre Comercial:</strong> {establishment.establecimientoComercial}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.infoItem}><strong>Número de Establecimiento:</strong> {establishment.numeroEstablecimiento}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.infoItem}><strong>Calle Principal:</strong> {establishment.callePrincipal}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.infoItem}><strong>Número de Edificación:</strong> {establishment.numeroEdificacion}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.infoItem}><strong>Calle Secundaria:</strong> {establishment.calleSecundaria}</Typography>
                    </Grid>
                </Grid>
            </Paper>
            {propietary.tipoContribuyente === "Natural" &&
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                    className={classes.addButton}
                >
                    Ingresar Actividad
                </Button>
            }
            {propietary.tipoContribuyente !== "Natural" && <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenAccountModal}
                className={classes.addButton}
            >
                Ingresar Cuenta
            </Button>
            }
            {propietary.tipoContribuyente === "Natural" &&
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Actividad</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Acción</TableCell>
                                <TableCell>Base Imponible</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {activityList.map((activity) => (
                                <TableRow key={activity.idActividad}>
                                    <TableCell>{activity.actividad}</TableCell>
                                    <TableCell>{activity.estado}</TableCell>
                                    <TableCell>{activity.baseImponible}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDelete(activity.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            {propietary.tipoContribuyente !== "Natural" &&
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Descripción</TableCell>
                                <TableCell>Fecha de Registro</TableCell>
                                <TableCell>Tipo de Cuenta</TableCell>
                                <TableCell>Valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accountList.map((account) => (
                                <TableRow key={account.idCuentas}>
                                    <TableCell>{account.descripcion}</TableCell>
                                    <TableCell>{account.fechaRegistro}</TableCell>
                                    <TableCell>{account.tipoCuenta}</TableCell>
                                    <TableCell>{account.valor}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <Modal
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={openModal}>
                    <div className={classes.modalPaper}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Nueva Actividad
                        </Typography>
                        <form>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="valor"
                                label="Valor"
                                name="valor"
                                value={valor}
                                onChange={handleValorChange}
                            />
                            <Autocomplete
                                freeSolo
                                options={activityOptions}
                                getOptionLabel={(option) => option.actividad}
                                onInputChange={handleSearchChange}
                                onChange={handleSelectActivity}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Buscar Actividad"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            />
                            <Box textAlign="center">
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubmit()}
                                    className={classes.submitButton}
                                >
                                    Agregar
                                </Button>
                            </Box>
                        </form>
                    </div>
                </Fade>
            </Modal>

            <Modal
                className={classes.modal}
                open={openAccountModal}
                onClose={handleCloseAccountModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
            >
                <Fade in={openAccountModal}>
                    <div className={classes.modalPaper}>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseAccountModal}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Ingresar Nueva Cuenta
                        </Typography>
                        <form>
                            <FormControl variant="outlined" className={classes.formControl} fullWidth required>
                                <InputLabel id="catalog-label">Cuentas</InputLabel>
                                <Select
                                    labelId="catalog-label"
                                    id="catalog"
                                    value={selectedCatalog}
                                    onChange={handleCatalogChange}
                                    label="Cuentas"
                                >
                                    {catalogs.map((option) => (
                                        <MenuItem key={option.catId} value={option.catNombre}>
                                            {option.catNombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl} fullWidth required>
                                <InputLabel id="catalog-desc-label">Descripción</InputLabel>
                                <Select
                                    labelId="catalog-desc-label"
                                    id="catalogDesc"
                                    value={selectedCatalogDesc}
                                    onChange={handleCatalogDescChange}
                                    label="Descripción"
                                >
                                    {catalogDesc.map((option) => (
                                        <MenuItem key={option.catId} value={option.catId}>
                                            {option.catNombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" className={classes.formControl} fullWidth required>
                                <InputLabel id="period-label">Periodo</InputLabel>
                                <Select
                                    labelId="period-label"
                                    id="period"
                                    value={selectedPeriod}
                                    onChange={handlePeriodChange}
                                    label="Periodo"
                                >
                                    {periods.map((option) => (
                                        <MenuItem key={option.idPeriodo} value={option.idPeriodo}>
                                            {`${option.fechaInicio.substring(0, 10)} - ${option.fechaFin.substring(0, 10)}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="accountValue"
                                label="Valor"
                                name="valor"
                                type="number"
                                value={accountValue}
                                onChange={(e) => setAccountValue(e.target.value)}
                            />
                            <Box textAlign="center">
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmitAccount}
                                    className={classes.submitButton}
                                >
                                    Agregar
                                </Button>
                            </Box>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        json: state.generalJson.json
    };
};

export default connect(mapStateToProps, null)(ActivityRegister);
