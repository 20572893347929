const data={
permisos:[
    "/submenu/bim/unidad",
    "/submenu/costos/producto_rentabilidad",
    "/submenu/costos/cuenta_presupuesto",
    "/submenu/do_cost_driver",
    "/submenu/do_ponderacion_de_cost_driver",
    "/submenu/do_modelo_de_distribucion",
    "/submenu/dp_cost_driver",
    "/submenu/dp_ponderacion_de_cost_driver",
    "/submenu/dp_modelo_de_distribucion"
],
estructura:
[
    {module_name:`costos` , items:[{path:`general`},{path:`distribuicion_overhead`},{path:`distribucion_a_punto`}]},
    {module_name:`bim`, items:[{path:`general_params`}]},
    {module_name:`rentabilidad`, items:[{path:`rentabilidad_PIT`},{path:`rentabilidad`}]},
    {module_name:`reportes`, items:[{path:`reportes`}]},
    {module_name:`batch`, items:[{path:`batch`}]},
    {module_name:`etl`, items:[{path:`etl`}]}
]}

function reducer(state=data, {type, payload})
{
    switch(type){
        case 'getmenus':{
            return data.estructura.filter(n => n.module_name===payload);
        }
        case 'getPermissions':{
            return data.permisos;
        }
        case 'actualizarPermisos':{
            return {
            ...state,
            permisos: payload
            }
        }
        default:
            return state;
    }
}

export default reducer;
