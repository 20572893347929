import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Fade, Backdrop, IconButton, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    width: '50%',
    borderRadius: theme.shape.borderRadius,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: '100%',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(0),
    width: '100%',
  },
}));

const NuevaPatente = ({ open, handleClose, handleAddPatent, handleUpdatePatent, enableEdition, userProfile, existingPatent, userName}) => {
  const classes = useStyles();

  const [cedula, setCedula] = useState('');
  const [nombrecompleto, setNombreCompleto] = useState('');
  const [fechaInicioActividad, setFechaInicioActividad] = useState('2024-01-01');
  const [estado, setEstado] = useState(1); // Estado por defecto "Activo"
  const [observacion, setObservacion] = useState('');
  const [enLiquidacion, setEnLiquidacion] = useState(0); // Liquidación por defecto "No"
  const [fechaInicioActividadCanton, setFechaInicioActividadCanton] = useState('2024-01-01');

  useEffect(() => {
    if (existingPatent && existingPatent.length > 0) {
      const patent = existingPatent[0]; // Siempre usaremos la primera patente encontrada
      setCedula(userProfile.cedula);
      setNombreCompleto(userProfile.nombres +' '+userProfile.apellidos);
      setFechaInicioActividad(patent.fechaInicioActividad.slice(0, -1));
      setEstado(patent.estado);
      setObservacion(patent.observacion);
      setEnLiquidacion(patent.enLiquidacion);
      setFechaInicioActividadCanton(patent.fechaInicioActividadCanton.slice(0, -1));
    } else if (userProfile) {
      setCedula(userProfile.cedula);
      setNombreCompleto(`${userProfile.nombres} ${userProfile.apellidos}`);
    }
  }, [existingPatent, userProfile]);

  const handleSubmit = () => {
    const newPatent = {
      idPropietario: userProfile.idPropietario,
      fechaInicioActividad,
      estado,
      usuario: userName,
      observacion,
      enLiquidacion,
      fechaInicioActividadCanton
    };
    const idPatenteTmp=existingPatent && existingPatent.length > 0 ? existingPatent[0].idPatente : null;
    if(idPatenteTmp){
      newPatent["idPatente"]=idPatenteTmp;
    }
    if (existingPatent && existingPatent.length > 0) {
      handleUpdatePatent(newPatent);
    } else {
      handleAddPatent(newPatent);
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {existingPatent && existingPatent.length > 0 ? 'Modificar Patente' : 'Nueva Patente'}
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" margin="normal" required fullWidth id="idPropietario" label="Propietario" value={nombrecompleto} onChange={e => setNombreCompleto(e.target.value)} autoComplete="idPropietario" className={classes.textField} readOnly disabled />
                <TextField id="fechaInicioActividad" label="Fecha de inicio de actividades" type="date" value={fechaInicioActividad} onChange={e => setFechaInicioActividad(e.target.value)} InputLabelProps={{ shrink: true }} variant="outlined" margin="normal" required fullWidth className={classes.textField} />
                <FormControl variant="outlined" className={classes.formControl} required>
                  <InputLabel id="estado-label">Estado</InputLabel>
                  <Select labelId="estado-label" id="estado" value={estado} onChange={(e) => setEstado(e.target.value)} label="Estado" className={classes.textField}>
                    <MenuItem value={1}>Activo</MenuItem>
                    <MenuItem value={0}>Inactivo</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" margin="normal" required fullWidth id="observacion" label="Observación" value={observacion} onChange={e => setObservacion(e.target.value)} autoComplete="observacion" multiline rows={4} className={classes.textField} />
                <FormControl variant="outlined" className={classes.formControl} required>
                  <InputLabel id="enLiquidacion-label">En Liquidación</InputLabel>
                  <Select labelId="enLiquidacion-label" id="enLiquidacion" value={enLiquidacion} onChange={(e) => setEnLiquidacion(e.target.value)} label="En Liquidación" className={classes.textField}>
                    <MenuItem value={1}>Sí</MenuItem>
                    <MenuItem value={0}>No</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="fechaInicioActividadCanton" label="Inicio Actividades Cantón" type="date" value={fechaInicioActividadCanton} onChange={e => setFechaInicioActividadCanton(e.target.value)} InputLabelProps={{ shrink: true }} variant="outlined" margin="normal" required fullWidth className={classes.textField} />
              </Grid>
            </Grid>
              <Button type="button" variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
                {existingPatent && existingPatent.length > 0 ? 'Guardar Cambios' : 'Registrar Patente'}
              </Button>
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default NuevaPatente;
