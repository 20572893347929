import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Container, Box, Typography, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CUIDADO_Y_CONTROL_AMBIENTAL from "../assets/subservices/CUIDADO_Y_CONTROL_AMBIENTAL.png";
import GEOMATICA_8 from "../assets/subservices/GEOMATICA_8.png";
import MOVILIDAD_8 from "../assets/subservices/MOVILIDAD_8.png";
import OBRAS_8 from "../assets/subservices/OBRAS_8.png";
import PARTICIPACION_8 from "../assets/subservices/PARTICIPACION_8.png";
import PLANIFICACION_TERRITORIAL_8 from "../assets/subservices/PLANIFICACION_TERRITORIAL_8.png";
import TURISMO_8 from "../assets/subservices/TURISMO_8.png";
import seguridad_ciudaddana_8 from "../assets/subservices/seguridad_ciudaddana_8.png";
import servicios_publicos_8 from "../assets/subservices/servicios_publicos_8.png";
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#f0f0f0',
    padding: theme.spacing(4),
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
  contentBox: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    maxWidth: '90%',
    margin: '0 auto',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  gridItem: {
    textAlign: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  icon: {
    height:50
  },
}));
const getLinkPath = (label) => `/geoportalv2/${label.toLowerCase()}`;
const services = [
  { name: 'Cuidado y Control Ambiental', image: CUIDADO_Y_CONTROL_AMBIENTAL, componentId:'567' },
  { name: 'Geomática', image: GEOMATICA_8 , componentId:'568'},
  { name: 'Movilidad', image: MOVILIDAD_8 , componentId:'569'},
  { name: 'Obras', image: OBRAS_8, componentId:'570' },
  { name: 'Participación', image: PARTICIPACION_8 , componentId:'571'},
  { name: 'Planificación Territorial', image: PLANIFICACION_TERRITORIAL_8, componentId:'726' },
  { name: 'Turismo', image: TURISMO_8, componentId:'572'},
  { name: 'Seguridad Ciudadana', image: seguridad_ciudaddana_8 , componentId:'577'},
  { name: 'Servicios Públicos', image: servicios_publicos_8 , componentId:'654'},
];

export default function SubServices() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/" className={classes.backButton}>
        <IconButton>
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <Box className={classes.contentBox}>
        <Typography variant="h1" className={classes.title}>
          Capas
        </Typography>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index} className={classes.gridItem}>
                <Link to={getLinkPath(service.componentId)}>
                <Paper className={classes.paper}>
                  <img src={service.image} alt={service.name} className={classes.icon} />
                </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}