import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchArea from './SearchArea';
import GeneralService from '../apis/GeneralService';
import { Panel } from 'primereact/panel';
import moment from 'moment';
import { NotificationManager } from 'react-notifications';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#3C8DBC",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    table: {
        maxWidth: '100%',
    },
    table2: {
        minWidth: '100%',
    },
    modal: {
        position: 'absolute',
        width: '80%',
        maxWidth: '800px',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            maxHeight: '90%',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    modalTableContainer: {
        maxHeight: '400px',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            maxHeight: '70vh',
        },
    }
}));

export default function ConsultaDeuda() {
    const classes = useStyles();
    const [showTable, setShowTable] = React.useState(false);
    const [data, setData] = React.useState(null);
    const [toPay, setToPay] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState([]);
    const [showCaptcha, setShowCaptcha] = React.useState(true);

    const handleOpen = (data) => {
        setModalData(data);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const doSubmit = () => {
        let user_captcha = document.getElementById('user_captcha_input').value;

        if (validateCaptcha(user_captcha) == true) {
            setShowCaptcha(false);
            loadCaptchaEnginge(6);
            document.getElementById('user_captcha_input').value = "";
        }

        else {
            alert('El código no es correcto. Intente nuevamente');
            document.getElementById('user_captcha_input').value = "";
        }
    };

    const searchAction = async (credentialValue) => {
        setShowTable(false);
        const tramiteIdResult = await GeneralService.getProcedure(credentialValue);
        if ((tramiteIdResult.data).length > 0) {
            setData(tramiteIdResult.data);
            setShowTable(true);
        }
        else {
            const cedulaResult = await GeneralService.getProcedureByDni(credentialValue);
            if (cedulaResult.data) {
                setData(cedulaResult.data);
                setShowTable(true);
            }
        }

    }
    useEffect(() => {
        loadCaptchaEnginge(6);
        document.getElementById('user_captcha_input').value = "";
    }, []);
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    
    const handleDownload = async (registroId) => {
        GeneralService.getDocumentName(registroId).then(response=>{
            const fileNames=response.data;
            fileNames.forEach(individualRecord=>{
                const fileToDownload=GeneralService.getFileAddress(individualRecord);
                openInNewTab(fileToDownload);
            });
        });
    };
    return (
        <React.Fragment>
            {showCaptcha ?
                <>
                    <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <div className="container" style={{ textAlign: "center" }}>
                            <div className="form-group">

                                <div className="col mt-3">
                                    <LoadCanvasTemplate />
                                </div>

                                <div className="col mt-3">
                                    <div><input placeholder="Ingrese el valor del captcha" id="user_captcha_input" name="user_captcha_input" type="text"></input></div>
                                </div>

                                <div className="col mt-3">
                                    <div><button class="btn btn-primary" onClick={() => doSubmit()}>Enviar</button></div>
                                </div>

                            </div>

                        </div>
                        </div>
                    </React.Fragment>
                </> :
                <>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={classes.modal}>
                                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                                <h2>Detalle</h2>
                                <div className={classes.modalTableContainer}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="left">Estado</StyledTableCell>
                                                <StyledTableCell align="left">Fecha</StyledTableCell>
                                                <StyledTableCell align="left">Observación</StyledTableCell>
                                                <StyledTableCell align="left">Técnico</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {modalData.map((estado, index) => (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="left">{estado.estado}</StyledTableCell>
                                                    <StyledTableCell align="left">{estado.fecha}</StyledTableCell>
                                                    <StyledTableCell align="left">{estado.observacion}</StyledTableCell>
                                                    <StyledTableCell align="left">{estado.tecnico}</StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </div>
                        </Fade>
                    </Modal>

                    <br />
                    <Panel header={"Consulta de Trámites"} style={{ maxWidth: "100%" }}>
                        <SearchArea searchAction={searchAction} />
                    </Panel>
                    <br />
                    <Panel header={""} style={{ maxWidth: "100%", fontSize: "25px" }}>
                        {showTable &&
                            <TableContainer component={Paper}>
                                <Table className={classes.table2} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">Asunto</StyledTableCell>
                                            <StyledTableCell align="left">Cédula</StyledTableCell>
                                            <StyledTableCell align="left">Nro. Guía</StyledTableCell>
                                            <StyledTableCell align="left">Técnico</StyledTableCell>
                                            <StyledTableCell align="left">Detalle</StyledTableCell>
                                            <StyledTableCell align="left">Descargar</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row) => (
                                            <StyledTableRow key={row.nroGuia}>
                                                <StyledTableCell align="left">{row.asunto}</StyledTableCell>
                                                <StyledTableCell align="left">{row.cedula}</StyledTableCell>
                                                <StyledTableCell align="left">{row.nroGuia}</StyledTableCell>
                                                <StyledTableCell align="left">{row.tecnico}</StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <VisibilityIcon onClick={() => handleOpen(row.listaEstados)} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <CloudDownloadIcon onClick={() => handleDownload(row.idTramite)} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Panel>
                </>
            }
        </React.Fragment>
    );
}
