import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Paper, MenuItem, Select, FormControl, InputLabel, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { NotificationManager } from 'react-notifications';
import PatenteService from '../apis/PatenteService';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    width: '80%',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const RegisterComponent = () => {
  let history = useHistory();
  const classes = useStyles();
  const [cedula, setCedula] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [direccion, setDireccion] = useState('');
  const [telefono, setTelefono] = useState('');
  const [clave, setClave] = useState('');
  const [repetirClave, setRepetirClave] = useState('');
  const [mail, setMail] = useState('');
  const [tipoContribuyente, setTipoContribuyente] = useState('');
  const [obligadoContabilidad, setObligadoContabilidad] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (cedula.length >= 10) {
        const patentService = await PatenteService;
        try {
          const response = await patentService.getUserProfile(cedula);
          if (response.data && response.data.length > 0) {
            const userProfile = response.data[0];
            setNombres(userProfile.nombres);
            setApellidos(userProfile.apellidos);
            setDireccion(userProfile.direccion);
            setTelefono(userProfile.telefono);
            setMail(userProfile.mail);
            setTipoContribuyente(userProfile.tipoContribuyente === 'Natural' ? '1' : '0');
            setObligadoContabilidad(userProfile.obligadoLlevarCont === 'Sí');
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      }
    };
    fetchUserProfile();
  }, [cedula]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (clave !== repetirClave) {
      NotificationManager.error("Las contraseñas no coinciden.", "", 5000, () => {});
      return;
    }
    const claveRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!claveRegex.test(clave)) {
      NotificationManager.error("La contraseña debe tener al menos 8 caracteres y un carácter especial.", "", 5000, () => {});
      return;
    }
    const patentService = await PatenteService;
    const data = {
      cedula,
      nombres,
      apellidos,
      direccion,
      telefono,
      clave,
      mail,
      tipoContribuyente,
      obligadoLlevarCont: obligadoContabilidad ? 1 : 0,
    };

    try {
      const response = await patentService.register(data);
      if (response.status === 200) {
        NotificationManager.success("Registro exitoso.", "", 5000, () => {});
        history.push("/patente");
      } else {
        NotificationManager.error("Error en el registro.", "", 5000, () => {});
      }
    } catch (error) {
      NotificationManager.error("Error en el registro.", "", 5000, () => {});
    }
  };

  const handleStart = () => {
    history.push("/patente");
  };

  return (
    <Grid container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Registro
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" margin="normal" required fullWidth>
                <InputLabel id="tipoContribuyente-label">Tipo de Contribuyente</InputLabel>
                <Select
                  labelId="tipoContribuyente-label"
                  id="tipoContribuyente"
                  value={tipoContribuyente}
                  onChange={e => setTipoContribuyente(e.target.value)}
                  label="Tipo de Contribuyente"
                >
                  <MenuItem value="1">Natural</MenuItem>
                  <MenuItem value="0">Jurídica</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cedula"
                label="Cédula"
                name="cedula"
                autoComplete="cedula"
                autoFocus
                value={cedula}
                onChange={e => setCedula(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="nombres"
                label={tipoContribuyente === '0' ? 'Razón Social' : 'Nombres'}
                name="nombres"
                autoComplete="nombres"
                value={nombres}
                onChange={e => setNombres(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="apellidos"
                label={tipoContribuyente === '0' ? 'Nombre Comercial' : 'Apellidos'}
                name="apellidos"
                autoComplete="apellidos"
                value={apellidos}
                onChange={e => setApellidos(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="direccion"
                label="Dirección"
                name="direccion"
                autoComplete="direccion"
                value={direccion}
                onChange={e => setDireccion(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="telefono"
                label="Teléfono"
                name="telefono"
                autoComplete="telefono"
                value={telefono}
                onChange={e => setTelefono(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="clave"
                label="Clave"
                type="password"
                id="clave"
                autoComplete="current-password"
                value={clave}
                onChange={e => setClave(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="repetirClave"
                label="Repetir Clave"
                type="password"
                id="repetirClave"
                autoComplete="current-password"
                value={repetirClave}
                onChange={e => setRepetirClave(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="mail"
                label="Correo electrónico"
                name="mail"
                autoComplete="email"
                value={mail}
                onChange={e => setMail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={obligadoContabilidad}
                    onChange={e => setObligadoContabilidad(e.target.checked)}
                    name="obligadoContabilidad"
                    color="primary"
                  />
                }
                label="Obligado a llevar contabilidad"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrarse
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Button variant="body2" onClick={handleStart}>
                Iniciar sesión
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default RegisterComponent;
