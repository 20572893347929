import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { NotificationManager } from 'react-notifications';
import PatenteService from '../apis/PatenteService';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(10, 3, 3),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const CheckUserState = ({ json }) => {
  const classes = useStyles();
  let history = useHistory();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [userData,setUserData]= useState({});
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      const patentService = await PatenteService;
      try {
        const response = await patentService.checkUserState(json.cedula);
        if (response.data === true) {
          const userProfileTmp = await patentService.getUserProfile(json.cedula);
          setUserData(userProfileTmp.data[0]);
          setShowForm(true);
        } else {
          history.push("/company_profile");
        }
      } catch (error) {
        NotificationManager.error("Error al verificar el estado del usuario.", "", 5000, () => {});
        history.push("/company_profile");
      }
    };

    checkUser();
  }, [json, history]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newPassword !== repeatPassword) {
      NotificationManager.error("Las contraseñas no coinciden.", "", 5000, () => {});
      return;
    }
    const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      NotificationManager.error("La nueva contraseña debe tener al menos 8 caracteres y un carácter especial.", "", 5000, () => {});
      return;
    }
    const patentService = await PatenteService;
    try {
      const data = {...userData};
      data["clave"]=newPassword;
      const response = await patentService.updateUserInfo(data);
      if (response.status === 200) {
        NotificationManager.success("Contraseña cambiada exitosamente.", "", 5000, () => {});
        history.push("/company_profile");
      } else {
        NotificationManager.error("Error al cambiar la contraseña.", "", 5000, () => {});
      }
    } catch (error) {
      NotificationManager.error("Error al cambiar la contraseña.", "", 5000, () => {});
    }
  };

  return (
    <Grid container className={classes.root}>
      {showForm && (
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Resetear Contraseña
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="oldPassword"
              label="Contraseña Anterior"
              type="password"
              id="oldPassword"
              autoComplete="current-password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="Nueva Contraseña"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="repeatPassword"
              label="Repita Contraseña"
              type="password"
              id="repeatPassword"
              autoComplete="new-password"
              value={repeatPassword}
              onChange={e => setRepeatPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Cambiar Contraseña
            </Button>
          </form>
        </Paper>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    json: state.generalJson.json
  };
}

export default connect(mapStateToProps, null)(CheckUserState);
