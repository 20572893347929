import React, { useState, useEffect } from 'react';
import { Modal, Fade, Backdrop, IconButton, Typography, Grid, TextField, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PatenteService from '../../apis/PatenteService';

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    outline: 'none',
    width: '50%',
    borderRadius: theme.shape.borderRadius,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: '100%',
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const NuevaActividad = ({ open, handleClose, handleAddActivity, handleUpdateActivity, enableEdition, idEstablecimiento, existingActivity, userName }) => {
  const classes = useStyles();

  const [valor, setValor] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [activityOptions, setActivityOptions] = useState([]);

  useEffect(() => {
    if (existingActivity) {
      setValor(existingActivity.baseImponible);
      setSelectedActivity({
        idActividad: existingActivity.idActividad,
        actividad: existingActivity.actividad,
      });
    }
  }, [existingActivity]);

  const handleValorChange = (event) => {
    setValor(event.target.value);
  };

  const handleSelectActivity = async (event, newValue) => {
    setSelectedActivity(newValue);
  };

  const handleSearchActivity = async (event) => {
    if (event && event.target) {
      const valorabuscar = event.target.value;
      if (valorabuscar.length >= 3) {
        try {
          const response = await PatenteService.listActivitiesSearch(valorabuscar);
          setActivityOptions(response.data);
        } catch (error) {
          console.error('Error fetching activity options:', error);
        }
      }
    }
  };

  const handleSubmit = () => {
    const newActivity = {
      idEstablecimiento,
      idActividad: selectedActivity.idActividad,
      actividad: selectedActivity.actividad,
      baseImponible: valor,
      usuario:userName,
      estado:1
    };
    if(existingActivity&&existingActivity["idEstablecimientoActividad"]){
      newActivity["idEstablecimientoActividad"]=existingActivity["idEstablecimientoActividad"];
    }
    if (existingActivity) {
      handleUpdateActivity(newActivity);
    } else {
      handleAddActivity(newActivity);
    }
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} className={classes.modal} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {existingActivity ? 'Modificar Actividad' : 'Nueva Actividad'}
          </Typography>
          <form>
            <TextField variant="outlined" margin="normal" required fullWidth id="valor" label="Valor" name="valor" value={valor} onChange={handleValorChange} className={classes.textField} />
            <Autocomplete
              freeSolo
              options={activityOptions}
              getOptionLabel={(option) => option.actividad}
              onChange={handleSelectActivity}
              onInputChange={handleSearchActivity}
              renderInput={(params) => (
                <TextField {...params} label="Buscar Actividad" margin="normal" variant="outlined" fullWidth className={classes.textField} />
              )}
              value={selectedActivity}
            />
            {enableEdition && (
              <Button type="button" variant="contained" color="primary" onClick={handleSubmit} className={classes.submitButton}>
                {existingActivity ? 'Guardar Cambios' : 'Agregar'}
              </Button>
            )}
          </form>
        </div>
      </Fade>
    </Modal>
  );
};

export default NuevaActividad;
