import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PatenteService from '../../apis/PatenteService';
import { NotificationManager } from 'react-notifications';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(3),
        width: '100%',
    },
    input: {
        display: 'none',
    },
    button: {
        margin: theme.spacing(1),
    },
    fileName: {
        marginTop: theme.spacing(1),
    },
}));

const ManageDocuments = ({ patentId, showButtons , usuario, userName}) => {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(null);
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        fetchDocuments();
    }, []);

    const fetchDocuments = async () => {
        const patentService = await PatenteService;
        try {
            const response = await patentService.listDocumentsByPatentId(patentId);
            if (Array.isArray(response.data)) {
                setDocuments(response.data);
            } else {
                setDocuments([]);
            }
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                let base64String = reader.result.split(',')[1];
                const documentName = selectedFile.name;
                const data = {
                    idPatente: patentId,
                    nombreDocumentos: base64String,
                    urlDocumentos: documentName,
                    usuario:userName,
                };

                const patentService = await PatenteService;
                try {
                    const response = await patentService.uploadDocument(data);
                    if (response.status === 200) {
                        NotificationManager.success("Documento guardado.", "", 5000);
                        setSelectedFile(null);
                        document.getElementById('upload-input').value = '';
                        fetchDocuments(); // Refresh documents list after upload
                    } else {
                        NotificationManager.error("Error en la carga del documento.", "", 5000);
                    }
                } catch (error) {
                    NotificationManager.error("Error en la carga del documento.", "", 5000);
                }
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleDownload = async (idDocumentos) => {
        const patentService = await PatenteService;
        try {
            const response = await patentService.downloadDocumentById(idDocumentos);
            if (Array.isArray(response.data) && response.data.length > 0) {
                const { nombre, url } = response.data[0];
                const link = document.createElement('a');
                link.href = `data:application/pdf;base64,${nombre}`;
                link.download = url;
                link.click();
            } else {
                NotificationManager.error("Error al descargar el documento.", "", 5000);
            }
        } catch (error) {
            NotificationManager.error("Error al descargar el documento.", "", 5000);
            console.error('Error downloading document:', error);
        }
    };

    return (
        <Paper className={classes.root}>
            {showButtons &&
                <>
                    <Typography variant="h6" gutterBottom>
                        Cargar Documentos
                    </Typography>
                    <input
                        accept="application/pdf"
                        className={classes.input}
                        id="upload-input"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <label htmlFor="upload-input">
                        <Button variant="contained" color="primary" component="span" className={classes.button}>
                            Seleccionar Archivo
                        </Button>
                    </label>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        onClick={handleUpload}
                        disabled={!selectedFile}
                    >
                        Cargar
                    </Button>
                    {selectedFile && (
                        <Typography variant="body2" className={classes.fileName}>
                            Archivo seleccionado: {selectedFile.name}
                        </Typography>
                    )}
                </>
            }
            <Typography variant="h6" style={{ marginTop: 16 }}>
                Documentos Cargados
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre del Documento</TableCell>
                            <TableCell>Fecha de Registro</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map((doc) => (
                            <TableRow key={doc.idDocumentos}>
                                <TableCell>
                                    <Link href="#" onClick={() => handleDownload(doc.idDocumentos)}>
                                        {doc.url}
                                    </Link>
                                </TableCell>
                                <TableCell>{doc.fechaRegistro}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleDownload(doc.idDocumentos)}
                                    >
                                        Descargar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper >
    );
};

export default ManageDocuments;
