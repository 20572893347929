import React, { Component } from 'react';
import Vector from "ol/layer/Vector";
import SourceVector from "ol/source/Vector";
import Draw from 'ol/interaction/Draw';
import olMapScreenshot from 'ol-map-screenshot';
import { OSM, Vector as VectorSource } from 'ol/source';
import { LineString, Polygon } from 'ol/geom';
import { getArea, getLength } from 'ol/sphere';
import Overlay from 'ol/Overlay';
import { unByKey } from 'ol/Observable';
import View from 'ol/View';
import '../css/measure.css';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';
import Legend from "ol-ext/legend/Legend";
import LegendControl from "ol-ext/control/Legend";
import { Style, Stroke, Circle, Fill, Icon as IconStyle } from 'ol/style';
import { LOGO_MEJIA_BASE64 } from '../../../assets/images/LOGO_MEJIA_BASE64.js';
import { LOGO_MUNICIPIO_BASE64 } from '../../../assets/images/LOGO_MUNICIPIO_BASE64.js';

//import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
const jspdf = require("jspdf");

class MapServices {
  constructor(){
    this.api_url="";
    this.geoserver_url="";
    fetch(`${process.env.PUBLIC_URL}/config.json`).then((r) => r.json())
    .then((initOptions) =>{
      this.api_url=initOptions.api_url;
      this.geoserver_url=initOptions.geoserver_url;
    });
  }
  setMeasure = (map, figureType, addLayer) => {

    const raster = new TileLayer({
      source: new OSM(),
    });

    const source = new VectorSource();

    const vector = new VectorLayer({
      source: source,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2,
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33',
          }),
        }),
      }),
    });

    /**
     * Currently drawn feature.
     * @type {import("../src/ol/Feature.js").default}
     */
    let sketch;

    /**
     * The help tooltip element.
     * @type {HTMLElement}
     */
    let helpTooltipElement;

    /**
     * Overlay to show the help messages.
     * @type {Overlay}
     */
    let helpTooltip;

    /**
     * The measure tooltip element.
     * @type {HTMLElement}
     */
    let measureTooltipElement;

    /**
     * Overlay to show the measurement.
     * @type {Overlay}
     */
    let measureTooltip;

    /**
     * Message to show when the user is drawing a polygon.
     * @type {string}
     */
    const continuePolygonMsg = 'Click to continue drawing the polygon';

    /**
     * Message to show when the user is drawing a line.
     * @type {string}
     */
    const continueLineMsg = 'Click to continue drawing the line';

    /**
     * Handle pointer move.
     * @param {import("../src/ol/MapBrowserEvent").default} evt The event.
     */
    const pointerMoveHandler = function (evt) {
      if (evt.dragging) {
        return;
      }
      /** @type {string} */
      let helpMsg = 'Click to start drawing';

      if (sketch) {
        const geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
          helpMsg = continuePolygonMsg;
        } else if (geom instanceof LineString) {
          helpMsg = continueLineMsg;
        }
      }

      helpTooltipElement.innerHTML = helpMsg;
      helpTooltip.setPosition(evt.coordinate);

      helpTooltipElement.classList.remove('hidden');
    };
    //if (addLayer) {
    // map.addLayer(raster);
    // map.addLayer(vector);
    //}

    //map.on('pointermove', pointerMoveHandler);

    map.getViewport().addEventListener('mouseout', function () {
      //helpTooltipElement.classList.add('hidden');
    });

    const typeSelect = document.getElementById('type');

    let draw; // global so we can remove it later

    /**
     * Format length output.
     * @param {LineString} line The line.
     * @return {string} The formatted length.
     */
    const formatLength = function (line) {
      const length = getLength(line);
      let output;
      if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
      } else {
        output = Math.round(length * 100) / 100 + ' ' + 'm';
      }
      return output;
    };

    /**
     * Format area output.
     * @param {Polygon} polygon The polygon.
     * @return {string} Formatted area.
     */
    const formatArea = function (polygon) {
      const area = getArea(polygon);
      let output;
      if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
      } else {
        output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
      }
      return output;
    };

    const addInteraction = () => {
      map.removeInteraction(map);
      map.removeOverlay(helpTooltip);
      const type = figureType === 'area' ? 'Polygon' : 'LineString';
      draw = new Draw({
        source: source,
        type: type,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
      });
      map.addInteraction(draw);

      createMeasureTooltip();
      //createHelpTooltip();

      let listener;
      draw.on('drawstart', function (evt) {
        // set sketch
        sketch = evt.feature;

        /** @type {import("../src/ol/coordinate.js").Coordinate|undefined} */
        let tooltipCoord = evt.coordinate;

        listener = sketch.getGeometry().on('change', function (evt) {
          const geom = evt.target;
          let output;
          if (geom instanceof Polygon) {
            output = formatArea(geom);
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            output = formatLength(geom);
            tooltipCoord = geom.getLastCoordinate();
          }
          measureTooltipElement.innerHTML = output;
          measureTooltip.setPosition(tooltipCoord);
        });
      });

      draw.on('drawend', function () {
        //measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
        //measureTooltipElement.classList.add('hidden');
        //measureTooltip.setOffset([0, -7]);
        // unset sketch
        //sketch = null;
        // unset tooltip so that a new one can be created
        //measureTooltipElement = null;

        //createMeasureTooltip();
        if (measureTooltipElement) {
          measureTooltipElement.parentNode.removeChild(measureTooltipElement);
        }

        unByKey(listener);
        map.removeInteraction(draw);
        //map.removeLayer(vector);
        //map.removeLayer(raster);
      });
    }

    /**
     * Creates a new help tooltip
     */
    function createHelpTooltip() {
      if (helpTooltipElement) {
        helpTooltipElement.parentNode.removeChild(helpTooltipElement);
      }
      helpTooltipElement = document.createElement('div');
      helpTooltipElement.className = 'ol-tooltip hidden';
      helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left',
      });
      map.addOverlay(helpTooltip);
    }

    /**
     * Creates a new measure tooltip
     */
    function createMeasureTooltip() {
      if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
      }
      measureTooltipElement = document.createElement('div');
      measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
      measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center',
        stopEvent: false,
        insertFirst: false,
      });
      map.addOverlay(measureTooltip);
    }

    addInteraction();
  }
  setPolygon = (map, figureType, setMapIteraction) => {
    const styleFunction = feature => {
      var geometry = feature.getGeometry();
      if (geometry.getType() === 'LineString') {
        var styles = [
          new Style({
            stroke: new Stroke({
              color: 'rgba(255, 102, 0, 1)',
              width: 3
            })
          })
        ];
        return styles;
      }
      if (geometry.getType() === 'Point') {
        var styles = [
          new Style({
            image: new Circle({
              radius: 5,
              stroke: new Stroke({ color: 'rgba(255, 0, 0, 1)' }),
              fill: new Fill({ color: 'rgba(255, 0, 0, 0.5)' })
            })
          })
        ];
        return styles;
      }
      if (geometry.getType() === 'Polygon') {
        var styles = [
          new Style({
            stroke: new Stroke({
              color: 'rgba(255, 102, 0, 0)',
              width: 3
            }),
            fill: new Fill({
              color: 'rgba(255, 102, 0, 0.3)'
            })
          })
        ];
        return styles;
      }
      return false;
    };
    const source = new SourceVector({ wrapX: false });
    const polygonBase = new Vector({ source });
    map.addLayer(polygonBase);

    const draw = new Draw({
      source: source,
      type: figureType,
      // style: styleFunction
    });
    setMapIteraction(draw);
    map.addInteraction(draw);
    draw.on('drawend', function () {
      map.removeInteraction(draw);
    });
  }
  getLegendFromServer = (layerName, resolution) => {
    const wmsSource = new ImageWMS({
      url:  `${this.geoserver_url}`,
      params: { 'LAYERS': layerName },
      ratio: 1,
      serverType: 'geoserver',
    });
    const graphicUrl = wmsSource.getLegendUrl(resolution);
    return graphicUrl;
  }
  addLegends = async (layers, map, legendCtrl, setLegendMap, setLegendUrls) => {
    console.log(layers);
    let legentUrls = [];
    map.removeControl(legendCtrl);
    legendCtrl = null;
    const resolution = map.getView().getResolution();
    let isFirstInteraction = true;
    layers.forEach(lRow => {
      let externalImgUrl = this.getLegendFromServer(lRow, resolution);
      legentUrls.push(externalImgUrl);

      var legend = new Legend({
        margin: 0,
        size: [200, 80],
        items: [
          {
            typeGeom: 'Point',
            style: new Style({
              image: new IconStyle({
                src: externalImgUrl,
                crossOrigin: 'anonymous'
              })
            })
          }
        ]
      });
      if (isFirstInteraction) {
        legendCtrl = new LegendControl({ legend: legend, margin: 0, collapsed: false });
        map.addControl(legendCtrl);
        isFirstInteraction = false;
      }
      else {
        map.addControl(new LegendControl({
          legend: legend,
          target: legendCtrl.element,
          collapsed: false
        })
        );
      }




    });
    setLegendMap(legendCtrl);
    setLegendUrls(legentUrls);
  }

  exportPdf = async (map, overviewMapControl, imagesToShow, legendUrls, associativeCovert, layers) => {
    associativeCovert["globalGad:cat_manzana"]="Manzanas";
    associativeCovert["globalGad:cat_predio"]="Predio";
    associativeCovert["globalGad:cat_piso_unidad_predio"]="Piso";
    console.log(associativeCovert);
    let titleArray = [];
    Object.keys(associativeCovert).filter(row => layers.includes(row))
      .forEach(row => titleArray.push(associativeCovert[row]));
    let title=titleArray.join("-");
    console.log(title);
    map.removeControl(overviewMapControl);
    const response = await olMapScreenshot.getScreenshot(map, {
      dim: [190, 160]
    });
    var img = response.img;

    //var imgMejia = `${LOGO_MEJIA_BASE64}`; 
    var imgMunicipio = `${LOGO_MUNICIPIO_BASE64}`;
    const pdf = new jspdf('p', undefined, "a4");
    pdf.rect(20, 20, pdf.internal.pageSize.width - 40, pdf.internal.pageSize.height - 40, 'S');
    //pdf.addImage(imgMejia, 'JPEG', 20, 20, 50, 20);
    pdf.addImage(imgMunicipio, 'JPEG', 160, 20, 20, 20);
    pdf.line(20, 45, pdf.internal.pageSize.width - 20, 45);
    pdf.text("Gobierno Municipal Cantón Mejía", 73, 30);
    pdf.setFontSize(10);
    pdf.setFont('undefined', 'bold');
    pdf.text("Dirección Geomática, Avalúos y Catastros", 85, 40);
    pdf.addImage(
      img,
      "png",
      30,
      50,
      150,
      100,
      'imageId',
      'FAST'
    );

    let initialWidth = 30;
    for (const [key, value] of Object.entries(legendUrls)) {
      let b64Img = await this.toDataURL(value);
      pdf.addImage(b64Img, 'JPEG', initialWidth, 155);
      initialWidth = initialWidth + 30;
    }
    for (const [key, value] of Object.entries(imagesToShow)) {
      let b64Img = await this.toDataURL(value);
      pdf.addPage();
      let dimensions = await this.getImageDimensions(b64Img);
      pdf.addImage(b64Img, 'JPEG', 25, 40, 150, parseInt((21.42 * parseInt(dimensions.h)) / 100), "layer" + key.toString());
    }
    pdf.save(title.length>0?title+'.pdf':'MapaBase.pdf');
    map.addControl(overviewMapControl);
  }
  getImageDimensions = async (file) => {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      };
      i.src = file
    })
  }
  toDataURL = (url) => {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {

          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          }
          reader.readAsDataURL(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

}
export default new MapServices();