import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import SearchIcon from '@material-ui/icons/Search';
import HighlightOff from '@material-ui/icons/HighlightOff';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PublicIcon from '@material-ui/icons/Public';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { useHistory } from "react-router";
import { connect } from "react-redux";
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import actualizarJson from '../redux/actions/actualizarJson';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import logoMejia from "../assets/portalimages/logomejia.png";
import Header from "./GeoPortalv2/Header";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Cayambe
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    minHeight: 48, // Ajusta la altura del AppBar aquí
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#f7f7f7 !important',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    marginTop: theme.spacing(6), // Ajusta este valor para darle un margen superior al contenido central
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  logoContainer: {
    backgroundColor:"#f7f7f7",
    flexGrow: 1, // Permite que el logo ocupe el espacio central
    display: 'flex',
    justifyContent: 'center', // Centra el logo horizontalmente
  },
  logo: {
    height: 100, // Ajusta el tamaño del logo según sea necesario
  },
  title: {
    flexGrow: 0, // Asegura que el título no ocupe espacio extra y permita centrar el logo
  },
}));


function MainTemplate({ json, actualizarJson, holder }) {
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [open2, setOpen2] = React.useState(true);
  const [tramites, setTramites] = React.useState(true);
  const [patentes, setPatentes] = React.useState(true);
  const [denuncias, setDenuncias] = React.useState(true);
  const [propietary, setPropietary] = React.useState(null);

  const handleClick = () => {
    setOpen2(!open2);
  };
  const handleTramitesClick = () => {
    setTramites(!tramites);
  };
  const handlePatentesClick = () => {
    setPatentes(!patentes);
  };
  const handleDenunciasClick = () => {
    setDenuncias(!denuncias);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const closeSession = () => {
    actualizarJson({});
    history.push("/patente");
  };
  const showValues = () => {
    let generalJson = { ...json };
    generalJson["edit"] = 0;
    console.log("nuevos valores",generalJson);
    actualizarJson({ generalJson });
  };
  const editValues = () => {
    let generalJson = { ...json };
    generalJson["edit"] = 1;
    actualizarJson({ generalJson });
    history.push("/company_profile");
  };

  useEffect(() => {
    if (json) {
      setPropietary(json.loginInfo || null);
    }
    else{
      setPropietary(null);
    }
  }, [json]);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.logoContainer}>
          <Header isGeneral={true}/>
        </div>
      </Toolbar>
    </AppBar>


      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button onClick={() => { history.push("/"); }}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="INICIO" />
            </ListItem>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="CATASTROS" />
              {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => { history.push("/consulta_deuda"); }}>
                  <ListItemIcon>
                    <AttachMoneyIcon />
                  </ListItemIcon>
                  <ListItemText primary="Deudas" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { history.push("/consulta_tramite"); }}>
                  <ListItemIcon>
                    <DescriptionIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tr&aacute;mites" />
                </ListItem>
                <ListItem button className={classes.nested} onClick={() => { history.push("/geoportal"); }}>
                  <ListItemIcon>
                    <PublicIcon />
                  </ListItemIcon>
                  <ListItemText primary="Geoportal" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handleTramitesClick}>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="PLANIFICACIÓN" />
              {tramites ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={tramites} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested} onClick={() => { history.push("/linea_fabrica"); }}>
                  <ListItemIcon>
                    <HomeWorkIcon />
                  </ListItemIcon>
                  <ListItemText primary="Iprus" />
                </ListItem>
                <ListItem button onClick={() => { history.push("/icus"); }}>
                  <ListItemIcon>
                    <LocationSearchingIcon />
                  </ListItemIcon>
                  <ListItemText primary="Icus" />
                </ListItem>
              </List>
            </Collapse>

            <ListItem button onClick={handlePatentesClick}>
              <ListItemIcon>
                <ContactMailIcon />
              </ListItemIcon>
              <ListItemText primary="PATENTE" />
              {patentes ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={patentes} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {propietary && propietary.cedula ?
                  (<>
                    <ListItem button className={classes.nested} onClick={showValues}>
                      <ListItemIcon>
                        <VisibilityIcon />
                      </ListItemIcon>
                      <ListItemText primary="Patentes" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={editValues}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary="Modificar Valores" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={() => { history.push("/close_patent"); }}>
                      <ListItemIcon>
                        <HighlightOff />
                      </ListItemIcon>
                      <ListItemText primary="Cerrar Patente" />
                    </ListItem>
                    <ListItem button className={classes.nested} onClick={closeSession}>
                      <ListItemIcon>
                        <LockOpenIcon />
                      </ListItemIcon>
                      <ListItemText primary="Cerrar Sesión" />
                    </ListItem>
                  </>
                  ) : (
                    <ListItem button className={classes.nested} onClick={() => { history.push("/patente"); }}>
                      <ListItemIcon>
                        <LockOpenIcon />
                      </ListItemIcon>
                      <ListItemText primary="Ingresar" />
                    </ListItem>
                  )}

              </List>
            </Collapse>
          </div>
        </List>
        <Divider />
      </Drawer>
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        {holder}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  breadcrumb2: state.breadcrumb.breadcrumb,
  json: state.generalJson.json
});
const mapDispatchToProps = {
  actualizarJson
}
export default connect(mapStateToProps, mapDispatchToProps)(MainTemplate);
