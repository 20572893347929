import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import GeneralService from '../../apis/GeneralService';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class BusquedaCedula extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            showInfo: false
        };
    }

    componentDidMount() {
        switch(this.props.type){
            case 1:{
                GeneralService.busquedaCedula(this.props.id).then(result=>{
                    this.setState({products:result.data.lista,showInfo:true});
                },
                error=>{
                    NotificationManager.error('Error...Por favor intente nuevamente..', "", 5000, () => {});
                });
                break;
            }
            case 2:{
                GeneralService.busquedaCatastral(this.props.id).then(result=>{
                    this.setState({products:result.data.lista,showInfo:true});
                },
                error=>{
                    NotificationManager.error('Error..Por favor intente nuevamente..', "", 5000, () => {});
                });
                break;
            }
            default: break;

        }

    }
    /*getCatastro = (rowData) => {
        if (parseInt(rowData.sinat)===0)return  "URBANO";
        return "RURAL";
    }*/
    render() {
        return (
            <div>
                <br />
                {this.state.showInfo&&
                <div className="card">
                    <DataTable value={this.state.products}
                    onSelectionChange={(e) => {
                        this.props.searchAction(e.value.preId,"predio",e.value.sinat);
                    }}
                    selectionMode="single" dataKey="preId"
                    >
                        <Column field="identificacionPropietario" header="Cedula"></Column>
                        <Column field="codigoCatastral" header="Código Catastral"></Column>
                        <Column field="nombrePropietario" header="Propietario"></Column>
                        <Column field="preId" header="Predio"></Column>
                        {/*<Column header="Catastro" body={this.getCatastro}></Column>*/}
                    </DataTable>
                </div>
            }
            </div>
        );
    }
}
export default BusquedaCedula;