import React from 'react';
import {NotificationContainer} from 'react-notifications';
import './App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import Router from "./componentes/Router";


function App() {
  return (
    <React.Fragment>
      <NotificationContainer/>
      <Router/>
    </React.Fragment>
  );
}

export default App;
