import React, { useState } from 'react';
import { Button, TextField, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
import { NotificationManager } from 'react-notifications';
import PatenteService from '../apis/PatenteService';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(10, 3, 3),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    marginTop: theme.spacing(-8),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPasswordComponent = () => {
  let history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [cedula, setCedula] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const patentService = await PatenteService;

    const data = {
      email,
      cedula
    };

    try {
      const response = await patentService.forgotPassword(data);
      if (response.status === 200) {
        NotificationManager.success("La contraseña fue enviada a su correo electrónico.", "", 5000, () => {});
        history.push("/patente");
      } else {
        NotificationManager.error("Error al enviar la contraseña.", "", 5000, () => {});
      }
    } catch (error) {
      NotificationManager.error("Error al enviar la contraseña.", "", 5000, () => {});
    }
  };

  return (
    <Grid container className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Olvidó su contraseña
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cedula"
            label="Cédula"
            name="cedula"
            autoComplete="cedula"
            autoFocus
            value={cedula}
            onChange={e => setCedula(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Enviar
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default ForgotPasswordComponent;
