import React, { useEffect, useRef, useState } from 'react';
import {
  Box, TextField, Button, IconButton, Typography, Tooltip, Modal, Backdrop, Fade, Dialog, DialogTitle, DialogContent, DialogActions, useMediaQuery, useTheme, Drawer, List, ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import {
  Search as SearchIcon, Layers as LayersIcon, Map as MapIcon, Straighten as StraightenIcon, Apps as AppsIcon,
  ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, Print as PrintIcon, Menu as MenuIcon
} from '@material-ui/icons';
import { createEmpty as createEmptyExtent, extend as extendExtent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import 'ol/ol.css';
import { fromLonLat, transform } from 'ol/proj';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import XYZ from 'ol/source/XYZ';
import OverviewMap from 'ol/control/OverviewMap';
import { defaults as defaultControls } from 'ol/control';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import Draw from 'ol/interaction/Draw';
import { unByKey } from 'ol/Observable';
import axios from 'axios';
import jsPDF from 'jspdf';
import './GeoPortalv2.css';
import lineabaja from '../../assets/images/lineabaja.png';
import { PopoverSearch, PopoverLayers, PopoverMapBase, PopoverMeasurements, PopoverSelectionTool } from './PopoverComponents';
import Header from './Header';
import ImageWMS from 'ol/source/ImageWMS';
import { Overlay } from 'ol';
import olMapScreenshot from 'ol-map-screenshot';
import logomejia from '../../assets/images/logomejia.png';
import html2canvas from 'html2canvas';
import { LOGO_MUNICIPIO_BASE64 } from '../../assets/images/LOGO_MUNICIPIO_BASE64.js';
import domtoimage from 'dom-to-image';
import { toPng } from 'html-to-image';
import { MyLocation as MyLocationIcon } from '@material-ui/icons';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import GeneralService from '../apis/GeneralService';
import { useParams } from 'react-router-dom';
import WMSCapabilities from 'ol/format/WMSCapabilities';
import RefreshIcon from '@material-ui/icons/Refresh';
import MouseIcon from '@material-ui/icons/Mouse';
import { getCenter } from 'ol/extent';

const GeoPortalv2 = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const [geoserverUrl, setGeoserverUrl] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [layersAnchorEl, setLayersAnchorEl] = useState(null);
  const [mapBaseAnchorEl, setMapBaseAnchorEl] = useState(null);
  const [measurementsAnchorEl, setMeasurementsAnchorEl] = useState(null);
  const [selectionToolAnchorEl, setSelectionToolAnchorEl] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [layerVisibility, setLayerVisibility] = useState({ cat_manzana: false, cat_predio: true, cat_piso_unidad_predio: false, cat_calle: false, cat_barrio: false, pugs: false, sig_valor_suelo: false });
  const [highlightedLayer, setHighlightedLayer] = useState(null);
  const [showMap, setShowMap] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPredioInfo, setSelectedPredioInfo] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [drawInteraction, setDrawInteraction] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [searchResultsByCedula, setSearchResultsByCedula] = useState([]);
  const [selectedCedulaResult, setSelectedCedulaResult] = useState(null);
  const [predioModalOpen, setPredioModalOpen] = useState(false);
  const [predioModalContent, setPredioModalContent] = useState(null);
  const [globalLayers, setGlobalLayers] = useState(['cat_manzana', 'cat_predio', 'cat_piso_unidad_predio', 'cat_calle', 'cat_barrio', 'pugs', 'sig_valor_suelo']);
  const [legendUrls, setLegendUrls] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const clickHandlerRef = useRef(null);
  const [locationLayer, setLocationLayer] = useState(null);
  const [predioInfo, setPredioInfo] = useState([]);
  const [propietaryInfo, setPropietaryInfo] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { componentId } = useParams();
  const [dynamicLayers, setDynamicLayers] = useState([]);
  const [codigoCatastralValue, setCodigoCatastralValue] = useState('');
  const [cedulaPropietarioValue, setCedulaPropietarioValue] = useState('');
  const [drawSource] = useState(new VectorSource());
  const [drawLayer] = useState(new VectorLayer({
    source: drawSource,
    style: new Style({
      fill: new Fill({ color: 'rgba(255, 255, 255, 0.2)' }),
      stroke: new Stroke({ color: '#ffcc33', width: 2 }),
      image: new CircleStyle({ radius: 7, fill: new Fill({ color: '#ffcc33' }) }),
    }),
  }));
  const updateLayers = async (id) => {
    const layers = [];
    try {
      const queryResult = await GeneralService.getSubCategory(id);
      if (typeof queryResult["data"] !== 'undefined') {
        for (const category of queryResult["data"]) {
          const { catId } = category;
          const queryResult2 = await GeneralService.getSubCategoryLayers(catId);
          if (typeof queryResult["data"] !== 'undefined') {
            if (Array.isArray(queryResult2["data"])) {
              layers.push(...queryResult2["data"]);
            }
          }
        }
      }
    } catch (error) {
      console.error('Error en updateLayers:', error);
    }
    setDynamicLayers(layers);
    console.log("las capas son...", layers);
    const initialVisibility = {};
    layers.forEach(layer => {
      initialVisibility[layer.layer] = false;
    });
    setLayerVisibility(prevVisibility => ({
      ...prevVisibility,
      ...initialVisibility
    }));
    setGlobalLayers(prevGlobalLayers => [
      ...prevGlobalLayers,
      ...layers.map(layer => layer.layer)
    ]);

    setTimeout(() => addLegends(), 0);

  };
  useEffect(() => {
    if (componentId) {
      updateLayers(componentId);
    }
    else {
      console.log('No existe id de componente');
    }
  }, [componentId]);


  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDeactivateTools = () => {
    if (drawInteraction) {
      mapInstance.current.removeInteraction(drawInteraction);
      setDrawInteraction(null);
      setIsDrawing(false);
    }
  };
  const handleRefresh = () => {
    //window.location.reload();
    setGeoserverUrl('');
    setIsMenuOpen(true);
    setSearchAnchorEl(null);
    setLayersAnchorEl(null);
    setMapBaseAnchorEl(null);
    setMeasurementsAnchorEl(null);
    setSelectionToolAnchorEl(null);
    setSelectedTab(0);
    setLayerVisibility({
      cat_manzana: false, cat_predio: true, cat_piso_unidad_predio: false,
      cat_calle: false, cat_barrio: false, pugs: false, sig_valor_suelo: false
    });
    setHighlightedLayer(null);
    setShowMap(true);
    setIsModalOpen(false);
    setSelectedPredioInfo(null);
    setDrawInteraction(null);
    setSearchResult(null);
    setSearchResultsByCedula([]);
    setSelectedCedulaResult(null);
    setIsDrawing(false);
    vectorSource.clear(); // Limpiar las figuras del mapa
    mapInstance.current.getView().setCenter(fromLonLat([-78.567062, -0.511274])); // Restablecer el centro
    mapInstance.current.getView().setZoom(16); // Restablecer el zoom
    handleResetClick();
  };

  const overviewMapControl = useRef(null);

  const vectorSource = new VectorSource();
  const getLegendFromServer = (layerName, resolution) => {
    const wmsSource = new ImageWMS({
      url: `https://servicios.municipiodemejia.gob.ec/geoserver/globalGad/wms`,
      params: { 'LAYERS': layerName },
      ratio: 1,
      serverType: 'geoserver',
    });
    const graphicUrl = wmsSource.getLegendUrl(resolution);
    return graphicUrl;
  }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchLegendInfo = async (layerNames, resolution) => {
    console.log("pasamos por aqui");
    const legendUrls = [];
    console.log(layerNames);
    for (const layerName of layerNames) {
      try {
        const legendUrl = getLegendFromServer(layerName, resolution);

        console.log("la url invocadas", legendUrl);
        const response = await axios.get(legendUrl, { responseType: 'blob' });
        console.log("la respuesta adecuada es", response);

        // Verificar que el blob recibido sea una imagen
        const contentType = response.headers['content-type'];
        if (!contentType.startsWith('image')) {
          console.warn(`La respuesta no es una imagen válida para la capa ${layerName}`);
          continue;
        }

        // Convertir el blob a base64
        const reader = new FileReader();
        reader.readAsDataURL(response.data);
        await new Promise((resolve) => {
          reader.onloadend = function () {
            const base64data = reader.result;
            legendUrls.push(base64data);
            resolve();
          };
        });
      } catch (error) {
        console.error(`Error fetching legend info for layer ${layerName}:`, error);
      }
    }

    return legendUrls;
  };
  const addLegends = async () => {
    const allLayers = [...globalLayers, ...dynamicLayers.map(layer => layer.layer)];
    const mainLayers = mapInstance.current.getLayers().getArray().filter(row =>
      allLayers.includes(row.getProperties()['name']) && row.getProperties()['visible']
    );
    const layersToShow = mainLayers.map(row => row.getProperties()['name']);
    const resolution = mapInstance.current.getView().getResolution();
    const result = await fetchLegendInfo(layersToShow, resolution);
    setLegendUrls(result);
  };

  const getPredioInfo = async (catastroKey) => {
    const queryResult = await GeneralService.getPredioInfo(catastroKey);
    setPredioInfo([]);
    if (typeof queryResult["data"] !== undefined) {
      console.log("predioINfo", queryResult["data"]);
      setPredioInfo(queryResult["data"]);
    }
  }
  const getPropietaryInfo = async (catastroKey) => {
    const queryResult = await GeneralService.getPropietaryInfo(catastroKey);
    setPropietaryInfo([]);
    if (typeof queryResult["data"] !== undefined) {
      console.log("propietaryInfo", queryResult["data"]);
      setPropietaryInfo(queryResult["data"]);
    }
  }

  const getComplementaryIfo = async (catastroKey) => {
    //catastroKey
    getPredioInfo(catastroKey);
    getPropietaryInfo(catastroKey);
  }



  useEffect(() => {
    const initializeMap = async () => {
      const response = await fetch(`${process.env.PUBLIC_URL}/config.json`);
      const initOptions = await response.json();
      setGeoserverUrl(initOptions.geoserver_url);

      if (mapRef.current && !mapInstance.current) {
        overviewMapControl.current = new OverviewMap({
          className: 'ol-overviewmap ol-custom-overviewmap',
          layers: [new TileLayer({ source: new OSM() })],
          collapsed: false,
        });
        const predioLayer = new TileLayer({
          source: new TileWMS({
            url: initOptions.geoserver_url,
            params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:cat_predio' },
            crossOrigin: 'anonymous',
          }),
          name: 'cat_predio',
          visible: true
        });
        if (!componentId) {
          mapInstance.current = new Map({
            target: mapRef.current,
            layers: [streetsLayer, satelliteLayer, vectorLayer, drawLayer, predioLayer],
            view: new View({ center: fromLonLat([-78.567062, -0.511274]), zoom: 16 }),
            controls: defaultControls().extend([overviewMapControl.current]),
          });
          setLayerVisibility(prevState => ({
            ...prevState,
            cat_predio: true
          }));
        }
        else {
          mapInstance.current = new Map({
            target: mapRef.current,
            layers: [streetsLayer, satelliteLayer, vectorLayer, drawLayer],
            view: new View({ center: fromLonLat([-78.567062, -0.511274]), zoom: 16 }),
            controls: defaultControls().extend([overviewMapControl.current]),
          });
        }
        addLegends();
        const layerGroup = mapInstance.current.getLayerGroup();
        const layers = layerGroup.getLayers();

        const handleLayerChange = () => {
          addLegends();
        };

        layers.on('add', handleLayerChange);
        layers.on('remove', handleLayerChange);

        clickHandlerRef.current = async (evt) => {
          if (isDrawing) {
            return;
          }
          const predioLayer = mapInstance.current.getLayers().getArray().find(layer => layer.get('name') === 'cat_predio');

          if (predioLayer && predioLayer.getVisible() && !componentId) {
            const view = mapInstance.current.getView();
            const viewResolution = view.getResolution();
            const url = predioLayer.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, view.getProjection(), {
              INFO_FORMAT: 'application/json',
            });

            if (url) {
              try {
                const response = await fetch(url);
                const data = await response.json();
                if (data.features.length > 0) {
                  const feature = data.features[0];
                  const properties = feature.properties;
                  drawModalProperties(properties.pre_codigo_catastral, feature);
                }
              } catch (error) {
                console.error('Error fetching feature info:', error);
              }
            }
          }
        };


        mapInstance.current.on('singleclick', clickHandlerRef.current);
      } else if (mapInstance.current) {
        mapInstance.current.updateSize();
      }
    };

    initializeMap();
  }, [isMenuOpen, geoserverUrl, componentId, isDrawing]);
  const simulateClick2 = (coordinate) => {
    const pixel = mapInstance.current.getPixelFromCoordinate(coordinate);

    // Crear un objeto de evento personalizado
    const event = {
      type: 'singleclick',  // Tipo de evento
      coordinate: coordinate,  // Coordenadas del clic
      pixel: pixel,  // Pixel en el mapa donde ocurre el clic
      map: mapInstance.current // El mapa en el que ocurre el evento
    };

    // Disparar el evento en el mapa
    mapInstance.current.dispatchEvent(event);
  };


  const simulateClickInPolygon = (geojsonFeature) => {
    const format = new GeoJSON();
    const features = format.readFeatures(geojsonFeature, {
      dataProjection: 'EPSG:3857',
      featureProjection: 'EPSG:3857',
    });

    if (features.length > 0) {
      const polygon = features[0].getGeometry(); // Suponiendo que es un solo polígono

      // Obtener el centroide del polígono
      const extent = polygon.getExtent();
      const center = getCenter(extent);

      // Simular el clic en el centroide del polígono
      simulateClick2(center);
    } else {
      console.error('No se encontraron características para simular el clic.');
    }
  };
  const drawModalProperties = async (catastroCode, feature = null) => {
    const predialInfoResponse = await axios.post(`https://globalgad.intelectus-system.com/catastro/rest/v1.0/consultas/buscarPredio/${catastroCode}`);
    getComplementaryIfo(catastroCode);
    const predialInfo = predialInfoResponse.data;

    const combinedInfo = {
      pre_codigo_catastral: catastroCode,
      propietario: predialInfo.propietario,
      foto: predialInfo.foto,
    };

    setImageUrl(`https://portal-ciudadano.municipiodemejia.gob.ec/CATASTROIMG/${catastroCode}`);
    setSelectedPredioInfo(combinedInfo);
    setIsModalOpen(true);

    if (feature) {
      drawPolygon(feature);
      mapInstance.current.updateSize();
    }
  }

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    style: new Style({
      fill: new Fill({ color: 'rgba(255, 255, 255, 0.2)' }),
      stroke: new Stroke({ color: '#ffcc33', width: 2 }),
      image: new CircleStyle({ radius: 7, fill: new Fill({ color: '#ffcc33' }) }),
    }),
  });

  const streetsLayer = new TileLayer({
    source: new OSM(),
    visible: true,
    name: 'streets',
  });

  const satelliteLayer = new TileLayer({
    source: new XYZ({
      attributions: ['Powered by Esri', 'Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community'],
      attributionsCollapsible: false,
      url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      crossOrigin: 'anonymous',
      maxZoom: 30,
    }),
    visible: false,
    name: 'satellite',
  });

  const handleSearch = async (codigoCatastral, isFromOtherSection = false) => {
    try {
      const response = await axios.get(`https://globalgad.intelectus-system.com/catastro/rest/v1.0/consultas/predioConsulta/${codigoCatastral}`);
      const data = response.data;

      if (!data || !Array.isArray(data.features)) {
        console.error('Datos no válidos');
        return;
      }

      const validFeatures = data.features.filter((feature) => feature.geometry !== null);

      const originalProjection = 'EPSG:32717';
      const destinationProjection = 'EPSG:3857';

      const transformCoordinates = (polygon) => {
        return polygon.map((ring) => ring.map(([x, y]) => transform([x, y], originalProjection, destinationProjection)));
      };

      const features = validFeatures.map((feature) => {
        if (['MultiPolygon', 'Polygon'].includes(feature.geometry.type)) {
          const isMultiPolygon = feature.geometry.type === 'MultiPolygon';
          const coordinates = isMultiPolygon ? feature.geometry.coordinates : [feature.geometry.coordinates];
          const transformedCoordinates = coordinates.map((polygon) => transformCoordinates(polygon));
          feature.geometry.coordinates = isMultiPolygon ? transformedCoordinates : transformedCoordinates[0];
        }
        return feature;
      });

      const transformedGeoJSON = { ...data, features };

      if (transformedGeoJSON.features.length > 0) {
        console.log("geojson", transformedGeoJSON);
        const feature = transformedGeoJSON.features[0];
        setSearchResult(feature);

        drawPolygon(feature);

        // Simular un clic después de dibujar el polígono
        setTimeout(() => {
          simulateClickInPolygon(feature);
        }, 500); // Pequeño retraso para asegurar que el mapa se ha actualizado
      } else {
        console.log('No se encontraron resultados');
      }
    } catch (error) {
      console.error('Error en la búsqueda:', error);
    }
  };

  const handleSearchByCedula = async (cedula) => {
    try {
      const response = await axios.post(`https://globalgad.intelectus-system.com/catastro/rest/v1.0/consultas/listaPredios/${cedula}`);
      const data = response.data;

      if (Array.isArray(data)) {
        setSearchResultsByCedula(data);
      } else {
        console.error('Datos no válidos');
      }
    } catch (error) {
      console.error('Error en la búsqueda por cédula:', error);
    }
  };

  const drawPolygon = (geojsonFeature) => {
    const format = new GeoJSON();
    // Leer las características del GeoJSON
    const features = format.readFeatures(geojsonFeature, {
      dataProjection: 'EPSG:3857',
      featureProjection: 'EPSG:3857',
    });

    // Definir el estilo del polígono
    const polygonStyle = new Style({
      fill: new Fill({ color: 'rgba(0, 128, 0, 0.5)' }),
      stroke: new Stroke({ color: '#008000', width: 2 }),
    });

    // Limpiar las características previas
    vectorSource.clear();

    // Añadir las nuevas características a la fuente vectorial
    features.forEach((feature) => {
      feature.setStyle(polygonStyle);  // Aplicar el estilo al polígono
      vectorSource.addFeature(feature);  // Añadir la característica a la fuente
    });

    // Ajustar la vista del mapa para que se ajuste al polígono dibujado
    const extent = features.reduce((acc, feature) => {
      const featureExtent = feature.getGeometry().getExtent();
      return [
        Math.min(acc[0], featureExtent[0]),
        Math.min(acc[1], featureExtent[1]),
        Math.max(acc[2], featureExtent[2]),
        Math.max(acc[3], featureExtent[3]),
      ];
    }, [Infinity, Infinity, -Infinity, -Infinity]);

    // Verifica que la extensión es válida antes de ajustar la vista del mapa
    if (!isNaN(extent[0]) && !isNaN(extent[1]) && !isNaN(extent[2]) && !isNaN(extent[3])) {
      mapInstance.current.getView().fit(extent, { duration: 1000, maxZoom: 30 });
    } else {
      console.error('Invalid extent calculated:', extent);
    }
  };


  const addDrawInteraction = (type) => {
    try {
      if (drawInteraction) {
        mapInstance.current.removeInteraction(drawInteraction);
      }

      const interaction = new Draw({
        source: drawSource,
        type: type,
      });

      let listener;
      let tooltipElement;
      let tooltipOverlay;

      interaction.on('drawstart', (evt) => {
        setIsDrawing(true);
        mapInstance.current.un('singleclick', clickHandlerRef.current);

        tooltipElement = document.createElement('div');
        tooltipElement.className = 'tooltip tooltip-measure';
        tooltipOverlay = new Overlay({
          element: tooltipElement,
          offset: [0, -15],
          positioning: 'bottom-center',
        });
        mapInstance.current.addOverlay(tooltipOverlay);

        listener = evt.feature.getGeometry().on('change', (e) => {
          const geom = e.target;
          let output;
          if (geom.getType() === 'Polygon') {
            output = formatArea(geom);
          } else if (geom.getType() === 'LineString') {
            output = formatLength(geom);
          }
          tooltipElement.innerHTML = output;
          tooltipOverlay.setPosition(geom.getLastCoordinate());
        });
      });

      interaction.on('drawend', (evt) => {
        unByKey(listener);
        mapInstance.current.removeOverlay(tooltipOverlay);
        setIsDrawing(false);
        mapInstance.current.on('singleclick', clickHandlerRef.current);

        // Add the drawn feature to the drawSource
        const feature = evt.feature;
        feature.setId(Date.now().toString());  // Set a unique id for the feature
        drawSource.addFeature(feature);

        // Fit view to the drawn feature
        const extent = createEmptyExtent();
        extendExtent(extent, feature.getGeometry().getExtent());
        mapInstance.current.getView().fit(extent, { padding: [50, 50, 50, 50], duration: 1000 });
      });

      mapInstance.current.addInteraction(interaction);
      setDrawInteraction(interaction);

      const handleKeyUp = (event) => {
        if (event.key === 'Escape') {
          mapInstance.current.removeInteraction(interaction);
          document.removeEventListener('keyup', handleKeyUp);
          setDrawInteraction(null);
          setIsDrawing(false);
          mapInstance.current.on('singleclick', clickHandlerRef.current);
        }
      };

      document.addEventListener('keyup', handleKeyUp);
    } catch (error) {
      console.error('Error in addDrawInteraction:', error);
    }
  };



  const formatLength = (line) => {
    const length = line.getLength();
    let output;
    if (length > 100) {
      output = `${(length / 1000).toFixed(2)} km`;
    } else {
      output = `${length.toFixed(2)} m`;
    }
    return output;
  };

  const formatArea = (polygon) => {
    const area = polygon.getArea();
    let output;
    if (area > 10000) {
      output = `${(area / 1000000).toFixed(2)} km²`;
    } else {
      output = `${area.toFixed(2)} m²`;
    }
    return output;
  };

  const handleSelectTool = (tool) => {
    switch (tool) {
      case 'circle':
        addDrawInteraction('Circle');
        break;
      case 'polygon':
        addDrawInteraction('Polygon');
        break;
      default:
        if (drawInteraction) {
          console.log("ingresa por aqui");
          mapInstance.current.removeInteraction(drawInteraction);
          setDrawInteraction(null);
          setIsDrawing(false);
        }
        break;
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setTimeout(() => {
      if (mapInstance.current) {
        mapInstance.current.updateSize();
      }
    }, 300);
  };

  const handleSearchClick = (event) => {
    setSearchAnchorEl(event.currentTarget);
  };

  const handleSearchClose = () => {
    setSearchAnchorEl(null);
  };

  const handleLayersClick = (event) => {
    setLayersAnchorEl(event.currentTarget);
  };

  const handleLayersClose = () => {
    setLayersAnchorEl(null);
  };

  const handleMapBaseClick = (event) => {
    setMapBaseAnchorEl(event.currentTarget);
  };

  const handleMapBaseClose = () => {
    setMapBaseAnchorEl(null);
  };

  const handleMeasurementsClick = (event) => {
    setMeasurementsAnchorEl(event.currentTarget);
  };

  const handleMeasurementsClose = () => {
    setMeasurementsAnchorEl(null);
  };

  const handleSelectionToolClick = (event) => {
    setSelectionToolAnchorEl(event.currentTarget);
  };

  const handleSelectionToolClose = () => {
    setSelectionToolAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const toggleLayerVisibility = (layer) => {
    setLayerVisibility((prevVisibility) => {
      const newVisibility = !prevVisibility[layer];

      if (mapInstance.current) {
        const layers = mapInstance.current.getLayers().getArray();
        const targetLayer = layers.find(l => l.get('name') === layer);

        if (targetLayer) {
          targetLayer.setVisible(newVisibility);
        } else if (newVisibility) {
          const newLayer = getLayerConfig(layer);
          if (newLayer) {
            newLayer.setVisible(true);
            mapInstance.current.addLayer(newLayer);
          }
        }
      }
      setTimeout(() => addLegends(), 0);

      return { ...prevVisibility, [layer]: newVisibility };
    });
  };


  const getLayerConfig = (layer) => {
    const staticLayerConfigs = {
      cat_manzana: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:cat_manzana' },
        }),
        name: 'cat_manzana',
        crossOrigin: 'anonymous',
      }),
      cat_predio: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:cat_predio' },
        }),
        name: 'cat_predio',
        visible: layerVisibility.cat_predio,
        crossOrigin: 'anonymous',
      }),
      cat_piso_unidad_predio: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:cat_piso_unidad_predio' },
        }),
        name: 'cat_piso_unidad_predio',
        crossOrigin: 'anonymous',
      }),
      cat_calle: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:cat_calle' },
        }),
        name: 'cat_calle',
        crossOrigin: 'anonymous',
      }),
      cat_barrio: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:cat_barrio' },
        }),
        name: 'cat_barrio',
        crossOrigin: 'anonymous',
      }),
      pugs: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:pugs' },
        }),
        name: 'pugs',
        crossOrigin: 'anonymous',
      }),
      sig_valor_suelo: new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: 'globalGad:sig_valor_suelo' },
        }),
        name: 'sig_valor_suelo',
        crossOrigin: 'anonymous',
      }),
    };
    if (staticLayerConfigs[layer]) {
      return staticLayerConfigs[layer];
    }
    const dynamicLayer = dynamicLayers.find(l => l.layer === layer);
    if (dynamicLayer) {
      return new TileLayer({
        source: new TileWMS({
          url: geoserverUrl,
          params: { FORMAT: 'image/png', VERSION: '1.1.1', tiled: true, LAYERS: dynamicLayer.layer },
        }),
        name: dynamicLayer.layer,
        crossOrigin: 'anonymous',
      });
    }
    console.log(`Layer configuration not found for: ${layer}`);
  };

  const removeLayer = (layerName) => {
    const layersToRemove = mapInstance.current
      .getLayers()
      .getArray()
      .filter((layer) => layer.get('name') === layerName);

    layersToRemove.forEach((layer) => mapInstance.current.removeLayer(layer));
  };

  const handleApplyClick = () => {
    const codigoCatastral = document.getElementById('codigoCatastral').value;
    handleSearch(codigoCatastral);
  };

  const handleApplyCedulaClick = () => {
    const cedula = document.getElementById('cedulaPropietario').value;
    handleSearchByCedula(cedula);
  };

  const handleResetClick = () => {
    setCodigoCatastralValue('');
    setCedulaPropietarioValue('');
    setSearchResult(null);
    setSearchResultsByCedula([]);

    // Close the modal if it's open
    setIsModalOpen(false);
    setSelectedPredioInfo(null);

    if (mapInstance.current) {
      // Clear all vector sources
      mapInstance.current.getLayers().getArray().forEach(layer => {
        if (layer instanceof VectorLayer) {
          const source = layer.getSource();
          if (source instanceof VectorSource) {
            source.clear();
          }
        }
      });

      // Clear the main vector source
      if (vectorSource) {
        vectorSource.clear();
      }

      // Clear the draw source
      if (drawSource) {
        drawSource.clear();
      }

      // Reset the view to the initial state
      mapInstance.current.getView().setCenter(fromLonLat([-78.567062, -0.511274]));
      mapInstance.current.getView().setZoom(16);
    }

    // Reset any drawing interactions
    if (drawInteraction) {
      mapInstance.current.removeInteraction(drawInteraction);
      setDrawInteraction(null);
    }

    setIsDrawing(false);

    // Re-enable click handler
    if (clickHandlerRef.current) {
      mapInstance.current.on('singleclick', clickHandlerRef.current);
    }
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedPredioInfo(null);
  };

  const handleCedulaResultClick = (claveCatastral) => {
    setSelectedCedulaResult(claveCatastral);
    handleSearch(claveCatastral, true);
  };

  const fitMapToLayer = (layer) => {
    if (layer.getSource().getExtent) {
      const extent = layer.getSource().getExtent();

      if (extent && extent.every(coord => !isNaN(coord) && isFinite(coord))) {
        mapInstance.current.getView().fit(extent, {
          padding: [50, 50, 50, 50],
          duration: 1000
        });
      } else {
        console.warn('Invalid extent for layer:', layer.get('name'));
      }
    } else if (layer.getSource() instanceof TileWMS || layer.getSource() instanceof ImageWMS) {
      fitMapToWMSLayer(layer);
    } else {
      console.warn('Layer source does not have getExtent method:', layer.get('name'));
    }
  };

  const fitMapToWMSLayer = (layer) => {
    // Obtener la vista actual del mapa
    const view = mapInstance.current.getView();

    // Obtener el centro y el zoom actual
    const center = view.getCenter();
    const zoom = view.getZoom();

    // Hacer un zoom out
    const newZoom = Math.max(zoom - 2, 0); // Reducimos el zoom en 2 niveles, pero no menos que 0

    // Animar la vista para hacer zoom out
    view.animate({
      center: center,
      zoom: newZoom,
      duration: 1000
    });
  };

  const handlePrint = async () => {
    try {
      // Remueve temporalmente el control de vista general para la captura
      if (overviewMapControl.current) {
        mapInstance.current.removeControl(overviewMapControl.current);
      }

      // Captura la imagen del mapa usando html-to-image
      const dataUrl = await toPng(mapRef.current);

      const pdf = new jsPDF('p', undefined, "a4");
      pdf.rect(20, 20, pdf.internal.pageSize.width - 40, pdf.internal.pageSize.height - 40, 'S');
      pdf.addImage(LOGO_MUNICIPIO_BASE64, 'JPEG', 160, 20, 20, 20);
      pdf.line(20, 45, pdf.internal.pageSize.width - 20, 45);
      pdf.text("Gobierno Municipal Cantón Mejía", 73, 30);
      pdf.setFontSize(10);
      pdf.setFont('undefined', 'bold');
      pdf.text("Dirección Geomática, Avalúos y Catastros", 85, 40);
      pdf.addImage(dataUrl, 'PNG', 30, 50, 150, 100);

      // Añadir las leyendas y otros elementos
      let initialWidth = 30;
      for (const url of legendUrls) {
        let b64Img = await toDataURL2(url);
        pdf.addImage(b64Img, 'JPEG', initialWidth, 155);
        initialWidth += 30;
      }

      pdf.save('MapaBase.pdf');

      // Restaurar el control de vista general después de la captura
      if (overviewMapControl.current) {
        mapInstance.current.addControl(overviewMapControl.current);
      }

    } catch (error) {
      console.error('Error en handlePrint:', error);
    }
  };


  const toDataURL2 = (url) => {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const changeBaseMap = (type) => {
    const layers = mapInstance.current.getLayers().getArray();
    layers.forEach((layer) => {
      if (layer.get('name') === 'streets') {
        layer.setVisible(type === 'streets');
      } else if (layer.get('name') === 'satellite') {
        layer.setVisible(type === 'satellite');
      }
    });
  };
  const handleShowLocation = () => {
    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser');
      return;
    }

    navigator.geolocation.getCurrentPosition((position) => {
      const coords = fromLonLat([position.coords.longitude, position.coords.latitude]);

      // Mueve el mapa a la ubicación actual del usuario
      mapInstance.current.getView().animate({ center: coords, zoom: 18 });

      // Dibuja un círculo en la ubicación del usuario
      if (locationLayer) {
        mapInstance.current.removeLayer(locationLayer);
      }

      const locationFeature = new Feature({
        geometry: new Point(coords),
      });

      const locationStyle = new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({ color: 'rgba(255, 0, 0, 0.5)' }),
          stroke: new Stroke({
            color: 'rgba(255, 0, 0, 1)',
            width: 2,
          }),
        }),
      });

      locationFeature.setStyle(locationStyle);

      const vectorSource = new VectorSource({
        features: [locationFeature],
      });

      const vectorLayer = new VectorLayer({
        source: vectorSource,
      });

      setLocationLayer(vectorLayer);
      mapInstance.current.addLayer(vectorLayer);

      // Efecto parpadeante
      let visible = true;
      const blink = setInterval(() => {
        visible = !visible;
        locationFeature.setStyle(
          visible
            ? new Style({
              image: new CircleStyle({
                radius: 10,
                fill: new Fill({ color: 'rgba(255, 0, 0, 0.5)' }),
                stroke: new Stroke({
                  color: 'rgba(255, 0, 0, 1)',
                  width: 2,
                }),
              }),
            })
            : null
        );
      }, 500);

      // Detener el parpadeo después de 5 segundos
      setTimeout(() => {
        clearInterval(blink);
      }, 5000);
    }, () => {
      alert('Unable to retrieve your location');
    });
  };

  const searchId = Boolean(searchAnchorEl);
  const layersId = Boolean(layersAnchorEl);
  const mapBaseId = Boolean(mapBaseAnchorEl);
  const measurementsId = Boolean(measurementsAnchorEl);
  const selectionToolId = Boolean(selectionToolAnchorEl);
  const mobileMenu = (
    <Box>
      <List>
        <ListItem>
          <Typography variant="h6">Clave Catastral</Typography>
        </ListItem>
        <ListItem>
          <TextField id="mobileCodigoCatastral" label="Ingresa número de catastro" variant="outlined" fullWidth margin="normal" />
        </ListItem>
        <ListItem>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button variant="contained" color="primary" size="small" onClick={() => handleSearch(document.getElementById('mobileCodigoCatastral').value)}>
              Buscar
            </Button>
            <Button variant="outlined" color="secondary" size="small" onClick={handleResetClick}>
              Limpiar
            </Button>
          </Box>
        </ListItem>
        <ListItem>
          <Typography variant="h6">Cédula del Propietario</Typography>
        </ListItem>
        <ListItem>
          <TextField id="mobileCedulaPropietario" label="Ingresa número de cédula" variant="outlined" fullWidth margin="normal" />
        </ListItem>
        <ListItem>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Button variant="contained" color="primary" size="small" onClick={() => handleSearchByCedula(document.getElementById('mobileCedulaPropietario').value)}>
              Buscar
            </Button>
            <Button variant="outlined" color="secondary" size="small" onClick={handleResetClick}>
              Limpiar
            </Button>
          </Box>
        </ListItem>
      </List>
      {searchResult && (
        <Box mt={2} px={2}>
          <Typography variant="body1">{`ID: ${searchResult.properties.codigoCatastral}`}</Typography>
          <Typography variant="body1">{`Propietario: ${searchResult.properties.dominio}`}</Typography>
          <Typography variant="body1">{`Área Total Terreno: ${searchResult.properties.areaTotalTerreno}`}</Typography>
          <Typography variant="body1">{`Área Total Construcción: ${searchResult.properties.areaTotalConstruccion}`}</Typography>
        </Box>
      )}
      {searchResultsByCedula.length > 0 && (
        <Box mt={2} px={2} style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Typography variant="h6">Resultados de Búsqueda</Typography>
          {searchResultsByCedula.map((result, index) => (
            <Box
              key={index}
              p={1}
              mb={1}
              style={{
                border: selectedCedulaResult === result.claveCatastral ? '2px solid #4A148C' : '1px solid #ddd',
                cursor: 'pointer',
              }}
              onClick={() => handleCedulaResultClick(result.claveCatastral)}
            >
              <Typography variant="body1">{`Clave Catastral: ${result.claveCatastral}`}</Typography>
              <Typography variant="body2">{`Contribuyente: ${result.contribuyente}`}</Typography>
              <Typography variant="body2">{`Dirección: ${result.direccion}`}</Typography>
              <Typography variant="body2">{`Lote: ${result.lote}`}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );

  return (
    <>
      <style>
        {`
          .ol-zoom-in, .ol-zoom-out {
            width: 40px;
            height: 40px;
            font-size: 20px;
          }
          .ol-zoom {
            top: 10px;
            left: 10px;
          }
          @media (max-width: 600px) {
            .ol-zoom {
              top: 70px;
            }
          }
        `}
      </style>
      <Box display="flex" flexDirection="column" height="100vh">
        {!isMobile && <Header />}
        <Box display="flex" flexGrow={1} position="relative">
          {(!isMobile && !componentId) && (
            <Box
              style={{
                width: isMenuOpen ? 300 : 0,
                transition: 'width 0.3s',
                overflow: 'hidden',
                padding: isMenuOpen ? 20 : 0,
                position: 'relative',
              }}
            >
              {isMenuOpen && (
                <Box>
                  <Box mb={2}>
                    <Typography variant="h6">Clave Catastral</Typography>
                    <TextField id="codigoCatastral" label="Ingresa número de catastro" variant="outlined" fullWidth value={codigoCatastralValue} onChange={(e) => setCodigoCatastralValue(e.target.value)} margin="normal" />
                    <Box display="flex" justifyContent="flex-end">
                      <Button variant="contained" color="primary" size="small" style={{ marginRight: 8 }} onClick={handleApplyClick}>
                        Buscar
                      </Button>
                      <Button variant="outlined" color="secondary" size="small" onClick={handleResetClick}>
                        Limpiar
                      </Button>
                    </Box>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="h6">Cédula del Propietario</Typography>
                    <TextField id="cedulaPropietario" label="Ingresa número de cédula" variant="outlined" fullWidth margin="normal" value={cedulaPropietarioValue}
                      onChange={(e) => setCedulaPropietarioValue(e.target.value)} />
                    <Box display="flex" justifyContent="flex-end">
                      <Button variant="contained" color="primary" size="small" style={{ marginRight: 8 }} onClick={handleApplyCedulaClick}>
                        Buscar
                      </Button>
                      <Button variant="outlined" color="secondary" size="small" onClick={handleResetClick}>
                        Limpiar
                      </Button>
                    </Box>
                  </Box>
                  {searchResult && (
                    <Box mt={2}>
                      <Typography variant="body1">{`ID: ${searchResult.properties.codigoCatastral}`}</Typography>
                      <Typography variant="body1">{`Propietario: ${searchResult.properties.dominio}`}</Typography>
                      <Typography variant="body1">{`Área Total Terreno: ${searchResult.properties.areaTotalTerreno}`}</Typography>
                      <Typography variant="body1">{`Área Total Construcción: ${searchResult.properties.areaTotalConstruccion}`}</Typography>
                    </Box>
                  )}
                  {searchResultsByCedula.length > 0 && (
                    <Box mt={2} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                      <Typography variant="h6">Resultados de Búsqueda</Typography>
                      {searchResultsByCedula.map((result, index) => (
                        <Box
                          key={index}
                          p={1}
                          mb={1}
                          style={{
                            border: selectedCedulaResult === result.claveCatastral ? '2px solid #4A148C' : '1px solid #ddd',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleCedulaResultClick(result.claveCatastral)}
                        >
                          <Typography variant="body1">{`Clave Catastral: ${result.claveCatastral}`}</Typography>
                          <Typography variant="body2">{`Contribuyente: ${result.contribuyente}`}</Typography>
                          <Typography variant="body2">{`Dirección: ${result.direccion}`}</Typography>
                          <Typography variant="body2">{`Lote: ${result.lote}`}</Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
              {!isMobile && (
                <IconButton
                  onClick={toggleMenu}
                  style={{
                    position: 'fixed',
                    top: '50%',
                    left: isMenuOpen ? '280px' : '0px',
                    transform: 'translateY(-50%)',
                    zIndex: 1000,
                    backgroundColor: '#4A148C',
                    color: 'white',
                    borderRadius: '50%',
                    boxShadow: '0 0 5px rgba(0,0,0,0.5)',
                    transition: 'left 0.3s',
                  }}
                >
                  {isMenuOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              )}
            </Box>
          )}
          <Box flexGrow={1} position="relative">
            <div ref={mapRef} style={{ flexGrow: 1, height: '100%', width: '100%' }}></div>
            <Box
              position="absolute"
              top={isMobile ? 70 : 10}
              left={isMobile ? 10 : 40}
              zIndex={1100}
              display="flex"
              flexDirection={isMobile ? 'column' : 'row'}
            >
              {isMobile && (
                <IconButton
                  style={{ backgroundColor: 'white', marginBottom: 8 }}
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              )}
              {!isMobile &&
                <Tooltip title="Imprimir">
                  <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handlePrint}>
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title="Capas">
                <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleLayersClick}>
                  <LayersIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Mapa Base">
                <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleMapBaseClick}>
                  <MapIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Medidas">
                <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleMeasurementsClick}>
                  <StraightenIcon />
                </IconButton>
              </Tooltip>
              {!isMobile &&
                <Tooltip title="Herramientas">
                  <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleSelectionToolClick}>
                    <AppsIcon />
                  </IconButton>
                </Tooltip>
              }
              <Tooltip title="Donde estoy">
                <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleShowLocation}>
                  <MyLocationIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Desactivar herramientas">
                <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleDeactivateTools}>
                  <MouseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refrescar página">
                <IconButton style={{ backgroundColor: 'white', marginRight: 8 }} onClick={handleRefresh}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>


            </Box>
          </Box>
        </Box>
        <PopoverSearch id={searchId} open={Boolean(searchAnchorEl)} anchorEl={searchAnchorEl} onClose={handleSearchClose} />
        <PopoverLayers
          id={layersId}
          open={Boolean(layersAnchorEl)}
          anchorEl={layersAnchorEl}
          onClose={handleLayersClose}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
          layerVisibility={layerVisibility}
          toggleLayerVisibility={toggleLayerVisibility}
          dynamicLayers={dynamicLayers}
          legendUrls={legendUrls}
          hasDynamicLayers={dynamicLayers.length > 0}
        />
        <PopoverMapBase id={mapBaseId} open={Boolean(mapBaseAnchorEl)} anchorEl={mapBaseAnchorEl} onClose={handleMapBaseClose} changeBaseMap={changeBaseMap} />
        <PopoverMeasurements
          id={measurementsId}
          open={Boolean(measurementsAnchorEl)}
          anchorEl={measurementsAnchorEl}
          onClose={handleMeasurementsClose}
          addInteraction={addDrawInteraction}
        />
        <PopoverSelectionTool
          id={selectionToolId}
          open={Boolean(selectionToolAnchorEl)}
          anchorEl={selectionToolAnchorEl}
          onClose={handleSelectionToolClose}
          handleSelectTool={handleSelectTool}
        />
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={isModalOpen}>
            <Box
              position="absolute"
              top="50%"
              left="50%"
              style={{
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                boxShadow: 24,
                padding: 16,
                outline: 'none',
                minWidth: '80%',
                maxWidth: '80%',
                maxHeight: '80%',
                overflow: 'auto',
              }}
            >
              {selectedPredioInfo && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                    <img
                      src={imageUrl}
                      alt="Imagen del Predio"
                      style={{ minHeight: "100px", maxHeight: "300px", marginTop: 16 }}
                      onError={() => setImageError(true)}
                    />
                  </div>
                  <Typography variant="h6" style={{ marginTop: 16 }}>DATOS DEL PREDIO</Typography>
                  <Box style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <table style={{ width: '100%', marginTop: 8, borderCollapse: 'collapse', border: '1px solid #ddd' }}>
                      <tbody>
                        {predioInfo.map((info, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>CLAVE CATASTRAL:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.claveCatastral}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>ESTADO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.estado}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>TIPO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.tipoPredio}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>DERECHOS Y ACCIONES:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.derechosAcciones}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>PROPIEDAD HORIZONTAL:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.propiedadHorizontal}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>PARROQUIA:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.parroquia}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>BARRIO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.barrio}</td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <Typography variant="h6" style={{ marginTop: 16 }}>DATOS DEL TITULAR</Typography>
                        {propietaryInfo.map((info, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>NOMBRE:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.contribuyente}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>CEDULA/RUC:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.identificacion}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>TIPO DE PROPIETARIO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.tipoPropietario}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>PRINCIPAL:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.propietarioPincipal}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>ALICUOTA:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.alicuota}</td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <Typography variant="h6" style={{ marginTop: 16 }}>DATOS VALORACION</Typography>
                        {predioInfo.map((info, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>AVALUO COMERCIAL:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.avaluoComercia}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>AVALUO DEL TERRENO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.avaluoTerreno}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>AVALUO CONSTRUCCION:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.avaluoContruccion}</td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <Typography variant="h6" style={{ marginTop: 16 }}>DATOS DEL LOTE</Typography>
                        {predioInfo.map((info, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>NUMERO DE LOTE:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.numeroLote}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>TIPO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.propiedadHorizontal}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>REGULARIZADO:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.regularizado}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>AREA DE ESCRITURA:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.areaEscritura}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>AREA GRAFICA:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.areaGrafica}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>AREA DE CONSTRUCCION:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.areaConstruccion}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>FRENTE:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.frente}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>FECHA DE ESCRITURA:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.fechaEscritura}</td>
                            </tr>
                            <tr>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}><strong>FECHA DE INSCRIPCION:</strong></td>
                              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{info.fechaInscripcion}</td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </Box>
                </>
              )}


            </Box>
          </Fade>
        </Modal>
        <Box display="flex" justifyContent="center" mt={2}>
          <img src={lineabaja} alt="Linea Baja" style={{ width: '100%', height: '5px' }} />
        </Box>
      </Box>
      <Dialog open={predioModalOpen} onClose={handleModalClose}>
        <DialogTitle>Información del Predio</DialogTitle>
        <DialogContent>
          {predioModalContent && (
            <Box>
              <Typography variant="body1">ID: {predioModalContent.id}</Typography>
              <Typography variant="body1">Propietario: {predioModalContent.owner}</Typography>
              <Typography variant="body1">Área: {predioModalContent.area}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} color="primary">Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Drawer
        variant="temporary"
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {!componentId && mobileMenu}
      </Drawer>
    </>
  );
};

export default GeoPortalv2;
