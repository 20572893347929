import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import StraightenIcon from '@material-ui/icons/Straighten';
import MapIcon from '@material-ui/icons/Map';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import TimelineIcon from '@material-ui/icons/Timeline';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

export const PopoverSearch = ({ id, open, anchorEl, onClose }) => (
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <Box p={2} style={{ width: 300 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Buscar</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <TextField
        label="Find address or place"
        variant="outlined"
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton>
              <SearchIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  </Popover>
);


export const PopoverLayers = ({
  id,
  open,
  anchorEl,
  onClose,
  selectedTab,
  handleTabChange,
  layerVisibility,
  toggleLayerVisibility,
  dynamicLayers,
  legendUrls,
  hasDynamicLayers
}) => {
  const renderLayerItems = () => {
    if (!hasDynamicLayers) {
      return (
        <>
          <ListItem button onClick={() => toggleLayerVisibility('cat_manzana')}>
            <ListItemIcon>
              {layerVisibility.cat_manzana ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Manzanas" />
          </ListItem>
          <ListItem button onClick={() => toggleLayerVisibility('cat_predio')}>
            <ListItemIcon>
              {layerVisibility.cat_predio ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Predio" />
          </ListItem>
          <ListItem button onClick={() => toggleLayerVisibility('cat_piso_unidad_predio')}>
            <ListItemIcon>
              {layerVisibility.cat_piso_unidad_predio ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Piso" />
          </ListItem>
          <ListItem button onClick={() => toggleLayerVisibility('cat_calle')}>
            <ListItemIcon>
              {layerVisibility.cat_calle ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Calle" />
          </ListItem>
          <ListItem button onClick={() => toggleLayerVisibility('cat_barrio')}>
            <ListItemIcon>
              {layerVisibility.cat_barrio ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Barrio" />
          </ListItem>
          <ListItem button onClick={() => toggleLayerVisibility('pugs')}>
            <ListItemIcon>
              {layerVisibility.pugs ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Pugs" />
          </ListItem>
          <ListItem button onClick={() => toggleLayerVisibility('sig_valor_suelo')}>
            <ListItemIcon>
              {layerVisibility.sig_valor_suelo ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary="Valor Suelo" />
          </ListItem>
        </>
      );
    } else {
      return dynamicLayers.map((layer) => (
        <ListItem button key={layer.layer} onClick={() => toggleLayerVisibility(layer.layer)}>
          <ListItemIcon>
            {layerVisibility[layer.layer] ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </ListItemIcon>
          <ListItemText primary={layer.titulo} />
        </ListItem>
      ));
    }
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <Box p={2} style={{ width: 300 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">CAPAS</Typography>
          <IconButton size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth">
          <Tab label="Capas" />
          <Tab label="Leyenda" />
        </Tabs>
        {selectedTab === 0 && (
          <List>
            {renderLayerItems()}
          </List>
        )}
        {selectedTab === 1 && (
          <Box p={2}>
            {legendUrls.length > 0 ? (
              legendUrls.map((url, index) => (
                <img key={index} src={url} alt={`Leyenda ${index + 1}`} style={{ maxWidth: '100%', marginBottom: '10px' }} />
              ))
            ) : (
              <Typography>No hay leyendas disponibles</Typography>
            )}
          </Box>
        )}
      </Box>
    </Popover>
  );
};
export const PopoverMapBase = ({ id, open, anchorEl, onClose, changeBaseMap }) => (
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <Box p={2} style={{ width: 300 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Mapa Base</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <ListItem button onClick={() => changeBaseMap('streets')}>
          <ListItemText primary="Calles" />
        </ListItem>
        <ListItem button onClick={() => changeBaseMap('satellite')}>
          <ListItemText primary="Satélite" />
        </ListItem>
      </List>
    </Box>
  </Popover>
);

export const PopoverMeasurements = ({ id, open, anchorEl, onClose, addInteraction }) => (
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <Box p={2} style={{ width: 200 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6">Medidas</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <ListItem button onClick={() => addInteraction('LineString')}>
          <ListItemIcon>
            <StraightenIcon />
          </ListItemIcon>
          <ListItemText primary="Perímetro" />
        </ListItem>
        <ListItem button onClick={() => addInteraction('Polygon')}>
          <ListItemIcon>
            <MapIcon />
          </ListItemIcon>
          <ListItemText primary="Área" />
        </ListItem>
      </List>
    </Box>
  </Popover>
);

export const PopoverSelectionTool = ({ id, open, anchorEl, onClose, handleSelectTool }) => (
  <Popover
    id={id}
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <Box p={2} style={{ width: 250 }}>
      <Box mt={2}>
        <Typography variant="subtitle1">Herramientas</Typography>
        <Box display="flex" justifyContent="space-around" mt={1} mb={2}>
          <Tooltip title="Dibujar círculo">
            <IconButton style={{ backgroundColor: 'white' }} onClick={() => handleSelectTool('circle')}>
              <RadioButtonUncheckedIcon /> {/* Ícono que representa un círculo */}
            </IconButton>
          </Tooltip>
          <Tooltip title="Dibujar polígono">
            <IconButton style={{ backgroundColor: 'white' }} onClick={() => handleSelectTool('polygon')}>
              <TimelineIcon /> {/* Ícono que representa un polígono */}
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancelar selección">
            <IconButton style={{ backgroundColor: 'white' }} onClick={() => handleSelectTool('')}>
              <CancelIcon /> {/* Ícono para cancelar la selección */}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  </Popover>
);
