import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [config, setConfig] = useState({});

  // Cargar el archivo de configuración
  useEffect(() => {
    fetch('config.json')
      .then(response => response.json())
      .then(configData => setConfig(configData));
  }, []);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const api_url = config.api_images_server;
  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label="settings" onClick={()=>{props.closeAditionalInfo();}}>
            <CloseIcon />
          </IconButton>
        }
        title={props.valoresTabla.barrio}
      />
      {(typeof props.valoresTabla.foto!=="undefined")&&
      <CardMedia
        className={classes.media}
        image={`${api_url}/CATASTROIMG/${props.valoresTabla.foto}`}
        title="Catastro"
      />}
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
        <b>Propietario:</b> {props.valoresTabla.propietario}  <br/>
        <b>Calle:</b> {props.valoresTabla.calle}  <br/>
        <b>Clave Catastral:</b> {props.valoresTabla.claveCatastral}  <br/>
        </Typography>
      </CardContent>
    </Card>
  );
}