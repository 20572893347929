import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function IconoPrincipal(props) {
  const classes = useStyles();
  return (
    <React.Fragment>

      <div style={{textAlign:"center"}}>
        <Link color="primary" href="#" onClick={preventDefault}>
          <img src={props.imagen} width="60%" />
          <br />
          {props.leyenda}
        </Link>
      </div>
    </React.Fragment>
  );
}
