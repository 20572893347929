import React, { Component } from 'react';
import LayerGroup from 'ol/layer/Group';
import * as olProj from 'ol/proj'
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlLayerTile from 'ol/layer/Tile';
import OlSourceOsm from 'ol/source/OSM';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Icon from 'ol/style/Icon';
import Point from 'ol/geom/Point';
import Translate from 'ol/interaction/Translate';
import Collection from 'ol/Collection';
import '../../react-geo.css';
import 'ol/ol.css';
import 'antd/dist/antd.css';
import Grid from '@material-ui/core/Grid';
import markerIcon from '../../../assets/images/marker.png';
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';

import {
    MapComponent
} from '@terrestris/react-geo';

proj4.defs('EPSG:32717', '+proj=utm +zone=17 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs ');
register(proj4);

class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            map: null,
            center: this.getPointFromGeo([this.props.ubicacionLote.centroideX,this.props.ubicacionLote.centroideY]),
            streetView: null,
            showMap: false,
            currentMarketPosition:null
        };
    }
    getPointFromLongLat(coordinate) {
        return olProj.transform(coordinate, 'EPSG:4326', 'EPSG:3857')
    }
    getPointFromGeo(coordinate) {
        return olProj.transform(coordinate, 'EPSG:32717', 'EPSG:3857')
    }
    getPolygon = () => {
        let coord1 = [this.props.ubicacionLote.centroideX,this.props.ubicacionLote.centroideY];
        let tempCoord=olProj.transform(coord1, 'EPSG:32717', 'EPSG:3857');
        this.setState({currentMarketPosition:tempCoord});
        let lineStyle = new Style({
            stroke: new Stroke({ color: 'red', width: 3 })
        });

        let styleMarker = new Style({
            image: new Icon({
                scale: .7, anchor: [0.5, 1],
                src: markerIcon
            })
        });

        let features = [];
        let rawPolygon=JSON.parse(this.props.ubicacionLote.poligono);
        let feature1 = new Feature({
            geometry: new Polygon(typeof rawPolygon.coordinates[0]!=="undefined"?rawPolygon.coordinates[0]:[])
        });

        /*********Adding Polygon********/
        feature1.getGeometry().transform('EPSG:32717', 'EPSG:3857');

        features.push(feature1);
        /*******End Adding Polygon******/

        /*********Adding Marker********/
        let marker1 = new Point(coord1);
        let featureMarker1 = new Feature(marker1);
        featureMarker1.getGeometry().transform('EPSG:32717', 'EPSG:3857');
        featureMarker1.setId('markerFeature');


        features.push(featureMarker1);
        /*********Adding Marker********/


        /*********Adding Traslate behavior*******/
        var translate1 = new Translate({
            features: new Collection([featureMarker1])
        });

        let vectorSource1 = new VectorSource({
            features: features
        });

        translate1.on('translateend', evt => {
            //var lonlat = olProj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
            var lonlat = evt.coordinate;
            if (!feature1.getGeometry().intersectsCoordinate(evt.coordinate)) {
                var featureToUpdate = vectorSource1.getFeatureById("markerFeature");
                let currentPoint = olProj.transform(coord1, 'EPSG:32717', 'EPSG:3857');
                featureToUpdate.getGeometry().setCoordinates(this.getPointFromGeo(coord1));
                this.setState({currentMarketPosition:currentPoint});
            }
            else this.setState({currentMarketPosition:lonlat});

        })
        /******End Adding Traslate behavior*******/



        const poligono = new VectorLayer({
            source: vectorSource1,
            style: [lineStyle, styleMarker],
            title: "Poligono"
        });

        const overlays = new LayerGroup({
            title: 'Overlays',
            layers: [
                poligono
            ]
        });
        this.state.map.addLayer(overlays);
        this.state.map.addInteraction(translate1);
    }
    getMarketPosition=()=>{
        this.props.registrarUbicacion(this.state.currentMarketPosition);
    }
    componentDidMount() {
        const streetView = new OlLayerTile({
            name: "streetView",
            source: new OlSourceOsm()
        });
        this.setState({ streetView }, () => {
            const map = new OlMap({
                view: new OlView({
                    center: this.state.center,
                    zoom: 20,
                }),
                layers: [this.state.streetView]
            });

            this.setState({ map }, () => {
                this.setState({ showMap: true });
                this.getPolygon();
            });
        });

    }
    render() {
        return (
            <React.Fragment>
                {this.state.showMap &&
                    <div style={{ width: "100%" }}>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={12} style={{ maxHeight: "40vh", minHeight: "40vh" }}>
                                <MapComponent
                                    map={this.state.map}
                                />
                            </Grid>
                        </Grid>
                        <button onClick={this.getMarketPosition}>Registrar Ubicaci&oacute;n</button>
                    </div>
                }
            </React.Fragment>
        );
    }

}
export default Map;